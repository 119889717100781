import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Text,
  Link,
  VStack,
  HStack,
} from '@chakra-ui/react';

const NewTableComponent = ({ s3Data, promptData, azure, gcp, mongo }) => {
  const [completeData, setCompleteData] = useState([]);

  useEffect(() => {
    setCompleteData([...s3Data, ...azure, ...gcp, ...promptData, ...mongo]);
    console.log('Complete Data', [
      ...s3Data,
      ...azure,
      ...gcp,
      ...promptData,
      ...mongo,
    ]);
  }, [s3Data, promptData, azure, gcp, mongo]);

  return (
    <Box>
      <Accordion defaultIndex={[0]} allowMultiple allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading
                  w="100%"
                  fontSize={'22px'}
                  margin={'0 0 20px 0'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  Responsible & Ethical AI Index
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Tabs>
              <HStack align="stretch" spacing={4}>
                {/* CSP Section */}
                <Box border={'1px solid #ccc'} borderRadius="8px" p={2}>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    mb={2}
                    textAlign={'center'}
                  >
                    CSP
                  </Text>
                  <TabList borderBottom="1px solid #ccc">
                    {completeData
                      .slice(0, s3Data.length + azure.length + gcp.length)
                      .map((data, index) => (
                        <Tab key={index}>
                          {index < s3Data.length
                            ? `AWS ${index + 1}`
                            : index < s3Data.length + azure.length
                            ? `AZURE ${index - s3Data.length + 1}`
                            : `GCP ${index - s3Data.length - azure.length + 1}`}
                        </Tab>
                      ))}
                  </TabList>
                </Box>

                {/* Prompt Section */}
                <Box border={'1px solid #ccc'} borderRadius="8px" p={2}>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    mb={2}
                    textAlign={'center'}
                  >
                    Prompt
                  </Text>
                  <TabList borderBottom="1px solid #ccc">
                    {completeData
                      .slice(
                        s3Data.length + azure.length + gcp.length,
                        s3Data.length +
                          azure.length +
                          gcp.length +
                          promptData.length
                      )
                      .map((data, index) => (
                        <Tab key={index}>Prompt {index + 1}</Tab>
                      ))}
                  </TabList>
                </Box>

                {/* Data Source Section */}
                <Box border={'1px solid #ccc'} borderRadius="8px" p={2}>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    mb={2}
                    textAlign={'center'}
                  >
                    Data Source
                  </Text>
                  <TabList borderBottom="1px solid #ccc">
                    {completeData
                      .slice(
                        s3Data.length +
                          azure.length +
                          gcp.length +
                          promptData.length
                      )
                      .map((data, index) => (
                        <Tab key={index}>
                          {index ===
                          completeData.length -
                            (s3Data.length +
                              azure.length +
                              gcp.length +
                              promptData.length) -
                            1
                            ? 'OracleDB 1'
                            : `MongoDB ${index + 1}`}
                        </Tab>
                      ))}
                  </TabList>
                </Box>
              </HStack>

              <TabPanels>
                {completeData.map((data, index) => (
                  <TabPanel key={index} px={0}>
                    {/* Prompt and Response Table */}
                    {data.prompt && (
                      <Table
                        my={2}
                        size="sm"
                        variant="simple"
                        border="1px"
                        borderColor="gray.600"
                        borderRadius="8px"
                        overflow="hidden"
                      >
                        {/* Table Header */}
                        <Thead
                          w="100%"
                          margin={'0 0 20px 0'}
                          alignItems={'center'}
                          textAlign={'center'}
                          background={'#222222'}
                        >
                          <Tr>
                            <Th
                              fontSize={'15px'}
                              fontWeight={'bold'}
                              color={'#ffff'}
                            >
                              Prompt
                            </Th>
                            <Th
                              fontSize={'15px'}
                              fontWeight={'bold'}
                              color={'#ffff'}
                            >
                              Response
                            </Th>
                          </Tr>
                        </Thead>
                        {/* Table Body */}
                        <Tbody
                          w="100%"
                          fontSize={'16px'}
                          alignItems={'center'}
                          textAlign={'center'}
                        >
                          <Tr
                            key={0}
                            _hover={{ bg: 'gray.50' }}
                            border={'2px solid #E2E8F0'}
                            borderLeft={'none'}
                            borderRight={'none'}
                            fontSize={'14px'}
                          >
                            <Td fontSize={'14px'}>
                              {completeData[index].prompt}
                            </Td>
                            <Td fontSize={'14px'}>
                              {completeData[index].response}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    )}
                    <Table
                      variant="simple"
                      border="1px"
                      borderColor="gray.600"
                      borderRadius="8px"
                      overflow="hidden"
                    >
                      <Thead
                        w="100%"
                        margin={'0 0 20px 0'}
                        alignItems={'center'}
                        textAlign={'center'}
                        background={'#222222'}
                      >
                        <Tr>
                          <Th
                            fontSize={'15px'}
                            fontWeight={'bold'}
                            color={'#ffff'}
                          >
                            ID
                          </Th>
                          <Th
                            fontSize={'15px'}
                            fontWeight={'bold'}
                            color={'#ffff'}
                          >
                            Category
                          </Th>
                          <Th
                            fontSize={'15px'}
                            fontWeight={'bold'}
                            color={'#ffff'}
                          >
                            Status
                          </Th>
                          <Th
                            fontSize={'15px'}
                            fontWeight={'bold'}
                            color={'#ffff'}
                          >
                            Reason
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody
                        w="100%"
                        fontSize="16px"
                        alignItems="center"
                        textAlign="center"
                      >
                        {(() => {
                          // Determine the compliance result data
                          const complianceData =
                            data.AI_compliance_result ||
                            data.ai_compliance_result;

                          if (complianceData) {
                            return Object.keys(complianceData).map(
                              (headingKey, idx) => {
                                const categoryData = complianceData[headingKey];

                                if (categoryData) {
                                  return (
                                    <React.Fragment key={idx}>
                                      {/* Render Heading Row */}
                                      <Tr>
                                        <Th
                                          colSpan={4}
                                          fontWeight="bold"
                                          fontSize={18}
                                          bg="gray.50"
                                          color="gray.700"
                                          p="12px"
                                        >
                                          {headingKey === 'NIST'
                                            ? 'NIST AI-RMF'
                                            : headingKey === 'OECD'
                                            ? 'OECD AI Principles'
                                            : headingKey === 'OWASP'
                                            ? 'OWASP Top 10 (LLM)'
                                            : headingKey}
                                        </Th>
                                      </Tr>

                                      {/* Render Rows for Each Entry */}
                                      {Object.keys(categoryData).map(
                                        (itemKey, itemIdx) => {
                                          // Split itemKey to extract ID and Category
                                          const [id, category] =
                                            itemKey.split(' - ');

                                          return (
                                            <Tr
                                              key={itemIdx}
                                              _hover={{ bg: 'gray.50' }}
                                              border="2px solid #E2E8F0"
                                              borderLeft="none"
                                              borderRight="none"
                                              fontSize="14px"
                                            >
                                              <Td>
                                                {id === 'error' ? 'N/A' : id}
                                              </Td>{' '}
                                              {/* ID */}
                                              <Td>{category}</Td>{' '}
                                              {/* Category */}
                                              <Td>
                                                {/* Pill-like status */}
                                                {categoryData[itemKey]
                                                  ?.STATUS && (
                                                  <Box
                                                    display="inline-block"
                                                    px="3"
                                                    py="1"
                                                    borderRadius="full"
                                                    bg={
                                                      categoryData[itemKey]
                                                        ?.STATUS === 'PASS'
                                                        ? 'green.100'
                                                        : 'red.100'
                                                    }
                                                    color={
                                                      categoryData[itemKey]
                                                        ?.STATUS === 'PASS'
                                                        ? 'green.600'
                                                        : 'red.600'
                                                    }
                                                    fontWeight="medium"
                                                  >
                                                    {
                                                      categoryData[itemKey]
                                                        ?.STATUS
                                                    }
                                                  </Box>
                                                )}
                                              </Td>
                                              <Td>
                                                {categoryData[itemKey]?.REASON}
                                              </Td>{' '}
                                              {/* Reason */}
                                            </Tr>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              }
                            );
                          }

                          // If no compliance data is available
                          return (
                            <Tr>
                              <Td textAlign={'center'} colSpan={4}>
                                No Data Available
                              </Td>
                            </Tr>
                          );
                        })()}
                      </Tbody>
                    </Table>
                    {data?.s3_bucket_name ||
                    data?.account_name ||
                    data?.gcp_bucket_name ||
                    data?.source ? (
                      <AccordionItem border={'none'} mt={4}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Heading
                                w="100%"
                                fontSize={'22px'}
                                margin={'0 0 20px 0'}
                                alignItems={'center'}
                                justifyContent={'center'}
                              >
                                Decision Validation
                              </Heading>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Table
                            size="xs"
                            variant="simple"
                            border="1px"
                            borderColor="gray.600"
                            borderRadius="8px"
                            overflow="hidden"
                          >
                            {/* Table Header */}
                            <Thead
                              w="100%"
                              alignItems={'center'}
                              textAlign={'center'}
                              background={'#222222'}
                            >
                              <Tr>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'13px'}
                                  color={'#ffff'}
                                  textAlign="center"
                                  whiteSpace="nowrap"
                                >
                                  AWS
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'13px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Vulnerable
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'13px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Details
                                </Th>
                                <Th
                                  width={'7%'}
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'13px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Domain
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'11px'}
                                  color={'#ffff'}
                                >
                                  Confidence Score
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'13px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  File Name/Type
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'11px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Storage Name
                                </Th>
                                <Th
                                  textAlign="center"
                                  fontWeight={'bold'}
                                  fontSize={'11px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Date Created
                                </Th>
                              </Tr>
                            </Thead>
                            {/* Table Body */}
                            <Tbody
                              w="100%"
                              fontSize={'16px'}
                              alignItems={'center'}
                              textAlign={'center'}
                            >
                              <Tr
                                key={index}
                                _hover={{ bg: 'gray.50' }}
                                border={'2px solid #E2E8F0'}
                                borderLeft={'none'}
                                borderRight={'none'}
                                fontSize={'14px'}
                              >
                                <Td fontSize={'14px'} width={'150px'}>
                                  Data-at-Rest{' '}
                                  {data.s3_bucket_name
                                    ? `(S3)`
                                    : data.account_name
                                    ? `(Azure)`
                                    : data.gcp_bucket_name
                                    ? `(GCP)`
                                    : `(MongoDB)`}
                                </Td>{' '}
                                <Td fontSize={'14px'}>
                                  <Box
                                    as="span"
                                    px="2"
                                    py="1"
                                    borderRadius="md"
                                    color={
                                      completeData[index]
                                        .CONFIDENTIAL_INFORMATION_PRESENT ===
                                      'Yes'
                                        ? 'red.800'
                                        : 'green.800'
                                    }
                                    bg={
                                      completeData[index]
                                        .CONFIDENTIAL_INFORMATION_PRESENT ===
                                      'Yes'
                                        ? 'red.100'
                                        : 'green.100'
                                    }
                                  >
                                    {
                                      completeData[index]
                                        .CONFIDENTIAL_INFORMATION_PRESENT
                                    }
                                  </Box>
                                </Td>
                                <Td fontSize={'12px'} maxWidth={'230px'}>
                                  {completeData[index]
                                    .DATA_VULNERABILITY_DETAILS ?? 'N/A'}
                                </Td>
                                <Td fontSize={'14px'} textAlign={'center'}>
                                  {completeData[index].Domain}
                                </Td>
                                <Td fontSize={'14px'} textAlign={'center'}>
                                  {completeData[index].VULNERABILITY_SCORE}
                                </Td>
                                <Td fontSize={'14px'} maxWidth={'272px'}>
                                  {completeData[index].file_name} (
                                  {completeData[index].file_type})
                                </Td>
                                <Td fontSize={'14px'}>
                                  {completeData[index].s3_bucket_name ||
                                    completeData[index].gcp_bucket_name ||
                                    completeData[index].collection_name ||
                                    completeData[index].container_name}
                                </Td>
                                <Td fontSize={'14px'}>
                                  {completeData[index].created_at
                                    ? completeData[index].created_at.split(
                                        ' '
                                      )[0]
                                    : 'N/A'}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </AccordionPanel>
                      </AccordionItem>
                    ) : (
                      <AccordionItem border={'none'} mt={4}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Heading
                                w="100%"
                                fontSize={'22px'}
                                margin={'0 0 20px 0'}
                                alignItems={'center'}
                                justifyContent={'center'}
                              >
                                Hallucination Status
                              </Heading>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Table
                            size={{ base: 'xs', md: 'xs', '2xl': 'sm' }}
                            variant="simple"
                            border="1px"
                            borderColor="gray.600"
                            borderRadius="8px"
                            overflow="hidden"
                          >
                            <Thead
                              w="100%"
                              margin={'0 0 20px 0'}
                              alignItems={'center'}
                              textAlign={'center'}
                              background={'#222222'}
                            >
                              <Tr>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Prompt
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Category
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Result
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Justification
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Domain
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                >
                                  Confidence Score
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Context Relevance
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Factual Accuracy
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Response Relevance
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody
                              w="100%"
                              fontSize={'16px'}
                              alignItems={'center'}
                              textAlign={'center'}
                            >
                              <Tr
                                key={index}
                                _hover={{ bg: 'gray.50' }}
                                border={'2px solid #E2E8F0'}
                                borderLeft={'none'}
                                borderRight={'none'}
                                fontSize={'14px'}
                              >
                                <Td width={'150px'} verticalAlign="top">
                                  Prompts (Real-Time)
                                </Td>
                                <Td verticalAlign="top">Hallucination</Td>
                                <Td verticalAlign="top">
                                  <Box
                                    as="span"
                                    px="2"
                                    py="1"
                                    borderRadius="md"
                                    color={
                                      completeData[index].Hallucination ===
                                      'Yes'
                                        ? 'red.800'
                                        : 'green.800'
                                    }
                                    bg={
                                      completeData[index].Hallucination ===
                                      'Yes'
                                        ? 'red.100'
                                        : 'green.100'
                                    }
                                  >
                                    {completeData[index].Hallucination}
                                  </Box>
                                </Td>
                                <Td verticalAlign="top">
                                  {completeData[index].Justification}
                                </Td>
                                <Td verticalAlign="top">
                                  {completeData[index].domain}
                                </Td>
                                <Td verticalAlign="top">
                                  {
                                    completeData[index]
                                      .HallucinationConfidenceScore
                                  }
                                </Td>
                                <Td verticalAlign="top">
                                  <Box
                                    as="div"
                                    textAlign="left"
                                    fontSize="13px"
                                  >
                                    {(() => {
                                      try {
                                        console.log(
                                          'explanationContextRelevance:',
                                          completeData[index]
                                            .explanationContextRelevance
                                        );
                                        if (
                                          !completeData[index]
                                            .explanationContextRelevance
                                        ) {
                                          throw new Error('N/A');
                                        }

                                        const contextRelevanceMatch =
                                          completeData[
                                            index
                                          ].explanationContextRelevance.match(
                                            /\{\s*([\s\S]+?)\s*\}/
                                          );
                                        if (!contextRelevanceMatch) {
                                          throw new Error(
                                            'explanationContextRelevance data format is invalid'
                                          );
                                        }

                                        const contextData = JSON.parse(
                                          `{${contextRelevanceMatch[1]}}`
                                        );

                                        return (
                                          <Box>
                                            <Text>
                                              <strong>Reasoning:</strong>{' '}
                                              {contextData.Reasoning}
                                            </Text>
                                            <Text>
                                              <strong>Choice:</strong>{' '}
                                              {contextData.Choice}
                                            </Text>
                                          </Box>
                                        );
                                      } catch (error) {
                                        console.error(
                                          'Error parsing explanationContextRelevance:',
                                          error
                                        );
                                        return (
                                          <Text>
                                            {completeData[index]
                                              .explanationContextRelevance
                                              ? 'Error displaying explanationContextRelevance'
                                              : 'N/A'}
                                          </Text>
                                        );
                                      }
                                    })()}
                                  </Box>
                                </Td>
                                <Td
                                  verticalAlign="top"
                                  overflowY="auto"
                                  maxH="200px"
                                  style={{
                                    overflowX: 'hidden',
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  <Box
                                    as="div"
                                    textAlign="left"
                                    fontSize="13px"
                                  >
                                    <Text fontWeight="bold" mb="2">
                                      Result:
                                    </Text>
                                    {(() => {
                                      let parsedData;
                                      try {
                                        parsedData =
                                          typeof completeData[index]
                                            .explanationFactualAccuracy ===
                                          'string'
                                            ? JSON.parse(
                                                completeData[index]
                                                  .explanationFactualAccuracy
                                              )
                                            : completeData[index]
                                                .explanationFactualAccuracy;
                                      } catch (error) {
                                        console.error(
                                          'Error parsing explanationFactualAccuracy:',
                                          error
                                        );
                                        return (
                                          <Text>Error displaying data</Text>
                                        );
                                      }

                                      if (parsedData && parsedData.Result) {
                                        return parsedData.Result.map(
                                          (factObj, index) => (
                                            <Box key={index} mb="3">
                                              <Text fontWeight="bold">
                                                Fact {factObj.Fact}
                                              </Text>
                                              <Text>
                                                <strong>Reasoning:</strong>{' '}
                                                {factObj.Reasoning}
                                              </Text>
                                              <Text>
                                                <strong>Judgement:</strong>{' '}
                                                {factObj.Judgement}
                                              </Text>
                                            </Box>
                                          )
                                        );
                                      } else {
                                        return <Text>N/A</Text>;
                                      }
                                    })()}
                                  </Box>
                                </Td>
                                <Td verticalAlign="top">
                                  <Box
                                    as="div"
                                    textAlign="left"
                                    fontSize="13px"
                                  >
                                    {(() => {
                                      try {
                                        if (
                                          !completeData[index]
                                            .explanationResponseRelevance
                                        ) {
                                          throw new Error('N/A');
                                        }

                                        const responsePrecisionMatch =
                                          completeData[
                                            index
                                          ].explanationResponseRelevance.match(
                                            /Response Precision:\s*([\d.]+)\s*\{([\s\S]+?)\}/
                                          );
                                        if (!responsePrecisionMatch) {
                                          throw new Error(
                                            'Response Precision data format is invalid'
                                          );
                                        }

                                        const precisionScore =
                                          responsePrecisionMatch[1].trim();
                                        const precisionData = JSON.parse(
                                          `{${responsePrecisionMatch[2]}}`
                                        );

                                        const responseRecallMatch =
                                          completeData[
                                            index
                                          ].explanationResponseRelevance.match(
                                            /Response Recall:\s*([\d.]+)\s*\{([\s\S]+?)\}/
                                          );
                                        if (!responseRecallMatch) {
                                          throw new Error(
                                            'Response Recall data format is invalid'
                                          );
                                        }

                                        const recallScore =
                                          responseRecallMatch[1].trim();
                                        const recallData = JSON.parse(
                                          `{${responseRecallMatch[2]}}`
                                        );

                                        return (
                                          <Box>
                                            {/* Response Precision */}
                                            <Box>
                                              <Text fontWeight="bold">
                                                Response Precision:
                                              </Text>
                                              <Text>{precisionScore}</Text>
                                              <Text>
                                                <strong>Reasoning:</strong>{' '}
                                                {precisionData.Reasoning}
                                              </Text>
                                              <Text>
                                                <strong>Choice:</strong>{' '}
                                                {precisionData.Choice}
                                              </Text>
                                            </Box>

                                            {/* Response Recall */}
                                            <Box mt="4">
                                              <Text fontWeight="bold">
                                                Response Recall:
                                              </Text>
                                              <Text>{recallScore}</Text>
                                              <Text>
                                                <strong>Reasoning:</strong>{' '}
                                                {recallData.Reasoning}
                                              </Text>
                                              <Text>
                                                <strong>Choice:</strong>{' '}
                                                {recallData.Choice}
                                              </Text>
                                            </Box>
                                          </Box>
                                        );
                                      } catch (error) {
                                        console.error(
                                          'Error parsing explanationResponseRelevance:',
                                          error
                                        );
                                        return (
                                          <Text>
                                            {completeData[index]
                                              .explanationResponseRelevance
                                              ? 'Error displaying explanationResponseRelevance'
                                              : 'N/A'}
                                          </Text>
                                        );
                                      }
                                    })()}
                                  </Box>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>

                          {/* New Input Results Table */}
                          <Table
                            my={4}
                            size="sm"
                            variant="simple"
                            border="1px"
                            borderColor="gray.600"
                            borderRadius="8px"
                            overflow="hidden"
                          >
                            <Thead
                              w="100%"
                              margin={'0 0 20px 0'}
                              alignItems={'center'}
                              textAlign={'center'}
                              background={'#222222'}
                            >
                              <Tr>
                                <Th
                                  colSpan={9}
                                  fontWeight="bold"
                                  fontSize={18}
                                  bg="gray.50"
                                  color="gray.700"
                                  p="12px"
                                >
                                  Input Results
                                </Th>
                              </Tr>
                              <Tr>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Name
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Confidence Score
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Reason
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Result
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody
                              w="100%"
                              fontSize={'16px'}
                              alignItems={'center'}
                              textAlign={'center'}
                            >
                              {Object.entries(
                                completeData[index].input_results_raw
                              ).map(([key, value]) => (
                                <Tr
                                  key={index}
                                  _hover={{ bg: 'gray.50' }}
                                  border={'2px solid #E2E8F0'}
                                  borderLeft={'none'}
                                  borderRight={'none'}
                                  fontSize={'14px'}
                                >
                                  <Td>{key}</Td>
                                  <Td>{value.confidence_score}</Td>
                                  <Td>{value.reason}</Td>
                                  <Td>
                                    {/* Pill-like status */}
                                    {value?.result && (
                                      <Box
                                        display="inline-block"
                                        px="3"
                                        py="1"
                                        borderRadius="full"
                                        bg={
                                          value?.result === 'PASS'
                                            ? 'green.100'
                                            : 'red.100'
                                        }
                                        color={
                                          value?.result === 'PASS'
                                            ? 'green.600'
                                            : 'red.600'
                                        }
                                        fontWeight="medium"
                                      >
                                        {value?.result}
                                      </Box>
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>

                          {/* New Output Results Table */}
                          <Table
                            size="sm"
                            variant="simple"
                            border="1px"
                            borderColor="gray.600"
                            borderRadius="8px"
                            overflow="hidden"
                          >
                            <Thead
                              w="100%"
                              margin={'0 0 20px 0'}
                              alignItems={'center'}
                              textAlign={'center'}
                              background={'#222222'}
                            >
                              <Tr>
                                <Th
                                  colSpan={9}
                                  fontWeight="bold"
                                  fontSize={18}
                                  bg="gray.50"
                                  color="gray.700"
                                  p="12px"
                                >
                                  Output Results
                                </Th>
                              </Tr>
                              <Tr>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Name
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Confidence Score
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Reason
                                </Th>
                                <Th
                                  fontWeight={'bold'}
                                  fontSize={'15px'}
                                  color={'#ffff'}
                                  whiteSpace="nowrap"
                                >
                                  Result
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody
                              w="100%"
                              fontSize={'16px'}
                              alignItems={'center'}
                              textAlign={'center'}
                            >
                              {Object.entries(
                                completeData[index].output_results_raw
                              ).map(([key, value]) => (
                                <Tr
                                  key={index}
                                  _hover={{ bg: 'gray.50' }}
                                  border={'2px solid #E2E8F0'}
                                  borderLeft={'none'}
                                  borderRight={'none'}
                                  fontSize={'14px'}
                                >
                                  <Td>{key}</Td>
                                  <Td>{value.confidence_score}</Td>
                                  <Td>{value.reason}</Td>
                                  <Td>
                                    {/* Pill-like status */}
                                    {value?.result && (
                                      <Box
                                        display="inline-block"
                                        px="3"
                                        py="1"
                                        borderRadius="full"
                                        bg={
                                          value?.result === 'PASS'
                                            ? 'green.100'
                                            : 'red.100'
                                        }
                                        color={
                                          value?.result === 'PASS'
                                            ? 'green.600'
                                            : 'red.600'
                                        }
                                        fontWeight="medium"
                                      >
                                        {value?.result}
                                      </Box>
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>

                          {/* Source Tracker Result Table */}
                          {completeData[index].source_tracker_result.length >
                            0 && (
                            <Table
                              my={3}
                              size="sm"
                              variant="simple"
                              border="1px"
                              borderColor="gray.600"
                              borderRadius="8px"
                              overflow="hidden"
                            >
                              <Thead
                                w="100%"
                                margin={'0 0 20px 0'}
                                alignItems={'center'}
                                textAlign={'center'}
                                background={'#222222'}
                              >
                                <Tr>
                                  <Th
                                    colSpan={9}
                                    fontWeight="bold"
                                    fontSize={18}
                                    bg="gray.50"
                                    color="gray.700"
                                    p="12px"
                                  >
                                    Source Tracker Result
                                  </Th>
                                </Tr>
                                <Tr>
                                  <Th
                                    fontWeight={'bold'}
                                    fontSize={'15px'}
                                    color={'#ffff'}
                                    whiteSpace="nowrap"
                                  >
                                    Source Url
                                  </Th>
                                  <Th
                                    fontWeight={'bold'}
                                    fontSize={'15px'}
                                    color={'#ffff'}
                                    whiteSpace="nowrap"
                                  >
                                    Matched Snippet
                                  </Th>
                                  <Th
                                    fontWeight={'bold'}
                                    fontSize={'15px'}
                                    color={'#ffff'}
                                    whiteSpace="nowrap"
                                  >
                                    Position
                                  </Th>
                                  <Th
                                    fontWeight={'bold'}
                                    fontSize={'15px'}
                                    color={'#ffff'}
                                    whiteSpace="nowrap"
                                  >
                                    Source Title
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody
                                w="100%"
                                fontSize={'16px'}
                                alignItems={'center'}
                                textAlign={'center'}
                              >
                                {Object.entries(
                                  completeData[index].source_tracker_result
                                ).map(([key, value]) => (
                                  <Tr
                                    key={index}
                                    _hover={{ bg: 'gray.50' }}
                                    border={'2px solid #E2E8F0'}
                                    borderLeft={'none'}
                                    borderRight={'none'}
                                    fontSize={'14px'}
                                  >
                                    <Td>
                                      <Box key={value.source_url}>
                                        <Link
                                          href={value.source_url}
                                          color="blue.500"
                                          isExternal
                                        >
                                          {value.source_url}
                                        </Link>
                                      </Box>
                                    </Td>
                                    <Td>{value.matched_snippet}</Td>
                                    <Td>{value.position}</Td>
                                    <Td>{value.source_title}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default NewTableComponent;
