import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Tbody,
  Td,
  Tr,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useState, useEffect } from 'react';
import { BsArrowUp } from 'react-icons/bs';
import updLogo from '../assets/images/updLogo.svg';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import { POST, GET } from '../utilities/ApiProvider';
import { Bar, Line, PolarArea } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 20,
  plugins: {
    legend: {
      position: 'top', // Display the legend at the top
      labels: {
        font: {
          size: 12, // Adjust font size for better visibility
        },
      },
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black', // Color of the data labels
      align: 'top', // Alignment of the data labels (e.g., 'top', 'bottom', 'middle')
      font: {
        weight: 'bold',
      },
    },
  },
};

const Policy = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);
  const [chartsData, setChartsData] = useState({
    _id: '675e05f7f1cd379d3026c1d7',
    execution_time: 6.48,
    input_results_raw: {
      Anonymize: 'PASS',
      BanTopics: 'FAIL',
      Bias: 'FAIL',
      InvisibleText: 'PASS',
      PromptInjection: 'PASS',
      Secrets: 'PASS',
      Sensitive: 'PASS',
      TokenLimit: 'PASS',
      Toxicity: 'PASS',
    },
    input_results_raw_new: {
      Anonymize: 'PASS_0.91',
      BanTopics: 'FAIL_0.77',
      Bias: 'FAIL_0.85',
      InvisibleText: 'PASS_0.88',
      PromptInjection: 'PASS_0.76',
      Secrets: 'PASS_0.95',
      Sensitive: 'PASS_0.93',
      TokenLimit: 'PASS_0.82',
      Toxicity: 'PASS_0.79',
    },
    input_results_sanitized: {},
    model_response_raw:
      "Your AI in the AWS cloud shows partial compliance with NIST AI RMF principles. The AI configurations lack comprehensive lifecycle management practices, which impacts compliance. However, there is an emphasis on explainability, governance, and continuous monitoring, which aligns with NIST's focus on trustworthiness and security. Overall, while there are areas of strength, improvements are needed for full compliance.",
    model_response_sanitized: '',
    output_results_raw: {
      Bias: 'FAIL',
      Deanonymize: 'PASS',
      MaliciousURLs: 'PASS',
      NoRefusal: 'PASS',
      Relevance: 'PASS',
      Sensitive: 'PASS',
      URLReachability: 'PASS',
    },
    output_results_raw_new: {
      Bias: 'FAIL_0.87',
      Deanonymize: 'PASS_0.92',
      MaliciousURLs: 'PASS_0.78',
      NoRefusal: 'PASS_0.89',
      Relevance: 'PASS_0.93',
      Sensitive: 'PASS_0.88',
      URLReachability: 'PASS_0.82',
    },
    output_results_sanitized: {},
    prompt:
      'how much our AI in AWS cloud is compliant with NIST AI RMF principles?',
    request_info: {
      browser: 'Python Requests',
      content_type: 'application/json',
      device: 'Other',
      full_url: 'http://167.172.153.181:7000/api/v1/llmops/analyze',
      http_method: 'POST',
      os: 'Other',
      request_headers: {
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate',
        Connection: 'keep-alive',
        'Content-Length': '38732',
        'Content-Type': 'application/json',
        Host: '167.172.153.181:7000',
        'User-Agent': 'python-requests/2.31.0',
      },
      request_timestamp: '2024-12-14 22:25:53',
      requester_ip: '137.184.129.214',
    },
    sanitization_flag: false,
    sanitized_prompt: '',
    stats: {
      prompt: {
        has_patterns: 'none',
        sentiment_nltk: 0,
        toxicity_label: 'non-toxic',
      },
      response: {
        has_patterns: 'none',
        refusal_similarity: false,
        relevance_to_prompt: 0.8199999928474426,
        sentiment_nltk: 0.86,
        toxicity_label: 'non-toxic',
      },
    },
  });
  const [firstChart, setFirstChart] = useState({
    labels: ['Anonymize', 'BanTopics', 'Bias'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [secondChart, setSecondChart] = useState({
    labels: ['InvisibleText', 'PromptInjection', 'Secrets'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [thirdChart, setThirdChart] = useState({
    labels: ['Sensitive', 'TokenLimit', 'Toxicity'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80],
        fill: false,
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        tension: 0.1,
      },
    ],
  });
  const [fourthChart, setFourthChart] = useState({
    labels: ['Bias', 'Deanonymize', 'MaliciousURLs'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [fifthChart, setFifthChart] = useState({
    labels: ['NoRefusal', 'Relevance', 'Sensitive'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [sixthChart, setSixthChart] = useState({
    labels: ['URLReachability'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65],
        fill: false,
        borderColor: ['rgb(255, 205, 86)'],
        tension: 0.1,
      },
    ],
  });
  const [statsChart, setStatsChart] = useState({
    labels: [],
    datasets: [],
  });

  const [navGptQuestion, setNavGptQuestion] = useState('');
  // const [navGptAnswer, setNavGptAnswer] = useState('');

  const submitQuery = async () => {
    setIsLoading(true);
    try {
      let response = await POST(
        `api/v1/rag1/chat`,
        { query: navGptQuestion },
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Content-Length': '<calculated when request is sent>',
            Host: '<calculated when request is sent>',
          },
        }
      );
      if (response?.answer) {
        setQuestionsArray(prev => [...prev, navGptQuestion]);
        setAnswersArray(prev => [...prev, response?.answer]);
        setNavGptQuestion('');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isCloseable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  const submitQueryWithButton = async defaultQ => {
    setIsLoading(true);
    try {
      let response = await POST(
        `api/v1/rag1/chat`,
        { query: defaultQ },
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      if (response?.answer) {
        setQuestionsArray(prev => [...prev, defaultQ]);
        setAnswersArray(prev => [...prev, response?.answer]);
        setNavGptQuestion('');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isCloseable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  const handleKeyPress = event => {
    if (event.keyCode === 13) {
      submitQuery();
    }
  };

  const buttonStyles = {
    border: '1px solid',
    borderColor: '#0000001a',
    borderRadius: '6px',
    height: '40px',
    fontWeight: '400',
    marginBottom: '12px !important',
    textAlign: 'left',
    fontSize: '13px',
    shadow: 'md',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    _hover: {
      backgroundColor: '#555555',
      color: '#ffff',
    },
  };

  const getChartData = async () => {
    setIsLoading(true);
    try {
      let res = await GET('api/v1/llmops/records', {}, 'third');
      console.log('Last Val', res[res.length - 1]);
      if (res && res.length > 0) {
        setChartsData(res[res.length - 1]);
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 4000,
        isClosable: true,
        position: 'top-right',
        status: 'false',
      });
    }
    setIsLoading(false);
  };

  const generateChartData = (labels, type, chart) => {
    const passData = [];
    const failData = [];
    const colors = { PASS: '#F57020', FAIL: '#1E86FF' };

    labels.forEach(label => {
      let valueStr;
      if (type === 'input') {
        valueStr = chartsData.input_results_raw_new[label];
      } else {
        valueStr = chartsData.output_results_raw_new[label];
      }

      const [status, value] = valueStr.split('_');
      if (status === 'PASS') {
        passData.push(parseFloat(value));
        failData.push(0); // Add 0 for "Fail" where it's "Pass"
      } else {
        passData.push(0); // Add 0 for "Pass" where it's "Fail"
        failData.push(parseFloat(value));
      }
    });

    return {
      labels: labels,
      datasets: [
        {
          label: 'Pass',
          data: passData,
          backgroundColor: colors.PASS,
        },
        {
          label: 'Fail',
          data: failData,
          backgroundColor: colors.FAIL,
        },
      ],
    };
  };

  useEffect(() => {
    setFirstChart(
      generateChartData(['Anonymize', 'BanTopics', 'Bias'], 'input')
    );
    setSecondChart(
      generateChartData(
        ['InvisibleText', 'PromptInjection', 'Secrets'],
        'input',
        'bar'
      )
    );
    setThirdChart(
      generateChartData(['Sensitive', 'TokenLimit', 'Toxicity'], 'input')
    );
    setFourthChart(
      generateChartData(['Bias', 'Deanonymize', 'MaliciousURLs'], 'output')
    );
    setFifthChart(
      generateChartData(
        ['NoRefusal', 'Relevance', 'Sensitive'],
        'output',
        'bar'
      )
    );
    setSixthChart(generateChartData(['URLReachability'], 'output'));
  }, [chartsData]);

  useEffect(() => {
    if (chartsData && chartsData.stats && chartsData.stats.response) {
      const numericData = Object.entries(chartsData.stats.response)
        .filter(([key, value]) => typeof value === 'number')
        .reduce(
          (acc, [key, value]) => {
            acc.labels.push(key.replace(/_/g, ' ')); // Replace underscores with spaces
            acc.data.push(value);
            return acc;
          },
          { labels: [], data: [] }
        );

      setStatsChart({
        labels: numericData.labels,
        datasets: [
          {
            label: 'Response Stats',
            data: numericData.data,
            backgroundColor: [
              '#FFBD43',
              '#F57020',
              '#33D156',
              '#1E86FF',
              '#7487EA',
              '#B55BB4',
              '#D898F1',
              '#667085',
              '#E3766C',
              '#9DAFEA',
              '#F52020',
              '#33B3BC',
            ],
          },
        ],
      });
    }
  }, [chartsData]);

  useEffect(() => {
    getChartData();
    return () => {};
  }, []);

  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'} pb={0}>
        <Tabs variant="soft-rounded" colorScheme="green" as={Stack}>
          {/* Current Location identifier */}
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <TabList>
              <Tab>
                <LocationHeader forcedText={'NavGPT'} />
              </Tab>
              <Tab>
                <LocationHeader forcedText={'LLMEval'} />
              </Tab>
            </TabList>
            <NotificationIcon />
          </Stack>

          {/* Content Section */}
          <TabPanels>
            <TabPanel
              as={Stack}
              alignItems={'center'}
              height={'83vh'}
              justifyContent={'space-between'}
            >
              <Stack height="100%">
                {answersArray?.length > 0 ? (
                  <Stack
                    overflowY="auto"
                    height={'700px'}
                    flexDirection="column"
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    w="100%"
                    padding={'20px 0'}
                  >
                    {answersArray?.map((val, ind) => {
                      return (
                        <Box
                          key={ind}
                          w="80% !important"
                          marginX="auto !important"
                        >
                          <Box
                            display={'flex'}
                            alignItems="center"
                            gap="20px"
                            mb="20px !important"
                            bgColor="#fff"
                            p="15px"
                            borderRadius={'15px'}
                          >
                            <Heading
                              fontSize="13px"
                              borderRadius="100%"
                              p="12px 6px"
                              border={'2px solid'}
                              borderColor={'primary.100'}
                            >
                              You:
                            </Heading>
                            <Text fontSize="15px">{questionsArray[ind]}</Text>
                          </Box>
                          <Box
                            display={'flex'}
                            alignItems="flex-start"
                            gap="20px"
                            mb="20px !important"
                            bgColor="#fff"
                            p="15px"
                            borderRadius={'15px'}
                          >
                            <Image
                              src={updLogo}
                              w="50px"
                              border="2px solid"
                              borderColor="primary.100"
                              borderRadius="100%"
                              p="6px"
                            />
                            <Text fontSize="15px">{answersArray[ind]}</Text>
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                ) : (
                  <Stack
                    height="100%"
                    justifyContent={'center'}
                    alignItems={'center'}
                    w="100%"
                    mb="20px"
                  >
                    <Box mb={10}>
                      <Image src={updLogo} margin="0 auto 10px" w="100px" />
                      <Heading fontSize="1.5rem">
                        How can I help you today with your Enterprise Al
                        journey?
                      </Heading>
                    </Box>

                    <Stack
                      align="center"
                      justify="center"
                      spacing={6}
                      p={4}
                      mt={5}
                    >
                      <Stack direction="row" spacing={6}>
                        <Button
                          size="lg"
                          shadow="md"
                          height="40px"
                          border="1px solid"
                          borderColor="#0000001a"
                          fontWeight="400"
                          fontSize="13px"
                          justifyContent="flex-start"
                          rounded="full"
                          backgroundColor="transparent"
                          _hover={{ bg: '#555555', color: '#ffff' }}
                        >
                          Healthcare
                        </Button>
                        <Button
                          size="lg"
                          bg="#EDF2F7"
                          shadow="md"
                          height="40px"
                          border="1px solid"
                          borderColor="#0000001a"
                          fontWeight="400"
                          fontSize="13px"
                          justifyContent="flex-start"
                          rounded="full"
                          _hover={{ bg: '#555555', color: '#ffff' }}
                          backgroundColor="transparent"
                        >
                          Financial
                        </Button>
                        <Button
                          size="lg"
                          bg="#EDF2F7"
                          shadow="md"
                          height="40px"
                          border="1px solid"
                          borderColor="#0000001a"
                          fontWeight="400"
                          fontSize="13px"
                          justifyContent="flex-start"
                          rounded="full"
                          _hover={{ bg: '#555555', color: '#ffff' }}
                          backgroundColor="transparent"
                        >
                          Manufacturing
                        </Button>
                      </Stack>
                      <Button
                        size="lg"
                        bg="#EDF2F7"
                        shadow="md"
                        border="1px solid"
                        borderColor="#0000001a"
                        fontWeight="400"
                        height="40px"
                        fontSize="13px"
                        justifyContent="flex-start"
                        rounded="full"
                        _hover={{ bg: '#555555', color: '#ffff' }}
                        backgroundColor="transparent"
                      >
                        Risk Management and Compliance Oversight
                      </Button>
                    </Stack>
                  </Stack>
                )}
                <Stack
                  height={answersArray?.length > 1 ? '125px' : '30%'}
                  justifyContent="end"
                >
                  <Box
                    w={answersArray?.length > 0 ? '52%' : '48rem'}
                    marginX="auto"
                  >
                    {answersArray?.length > 0 ? null : (
                      <>
                        {/* <Stack
                          align="center"
                          justify="center"
                          spacing={6}
                          p={4}
                        >
                          <Stack direction="row" spacing={6}>
                            <Button
                              size="lg"
                              bg="white"
                              shadow="sm"
                              borderWidth="1px"
                            >
                              Healthcare
                            </Button>
                            <Button
                              size="lg"
                              bg="white"
                              shadow="sm"
                              borderWidth="1px"
                            >
                              Financial
                            </Button>
                            <Button
                              size="lg"
                              bg="white"
                              shadow="sm"
                              borderWidth="1px"
                            >
                              Manufacturing
                            </Button>
                          </Stack>
                          <Button
                            size="lg"
                            bg="white"
                            shadow="sm"
                            borderWidth="1px"
                          >
                            Risk Management and Compliance Oversight
                          </Button>
                        </Stack> */}
                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="center"
                          mb={2}
                        >
                          <Button
                            sx={buttonStyles}
                            isDisabled={isLoading}
                            onClick={() =>
                              submitQueryWithButton(
                                'Multi-Agent Discovery and Provisioning'
                              )
                            }
                          >
                            Multi-Agent Discovery and Provisioning
                          </Button>
                          <Button
                            sx={buttonStyles}
                            isDisabled={isLoading}
                            onClick={() =>
                              submitQueryWithButton(
                                'Decision Sequence & Reasoning'
                              )
                            }
                          >
                            Decision Sequence & Reasoning
                          </Button>
                          <Button
                            sx={buttonStyles}
                            isDisabled={isLoading}
                            onClick={() =>
                              submitQueryWithButton(
                                'Coordination and Multi-Stage Task Execution'
                              )
                            }
                          >
                            Coordination and Multi-Stage Task Execution
                          </Button>
                          <Button
                            sx={buttonStyles}
                            isDisabled={isLoading}
                            onClick={() =>
                              submitQueryWithButton(
                                'Agents Trust and Performance Feedback'
                              )
                            }
                          >
                            Agents Trust and Performance Feedback
                          </Button>
                        </Stack>
                      </>
                    )}
                    <Box w="100%">
                      <Box
                        display="flex"
                        w="100% !important"
                        border="1px solid"
                        borderColor="#8A959E"
                        borderRadius="6px"
                        height="50px"
                        fontWeight="600"
                        marginBottom="15px !important"
                        textAlign="left"
                        fontSize="13px"
                        justifyContent="flex-start"
                        position="relative"
                      >
                        <Input
                          readOnly={isLoading}
                          onKeyDownCapture={handleKeyPress}
                          _focusVisible={{ outline: 'none' }}
                          height="48px"
                          border="none"
                          w="100%"
                          placeholder="Message NavGPT...."
                          value={navGptQuestion}
                          onChange={e => setNavGptQuestion(e.target.value)}
                        />
                        <Button
                          onClick={submitQuery}
                          isLoading={isLoading}
                          height="35px"
                          w="35px"
                          p="0"
                          m="6px 5px 0 0"
                          bgColor="primary.100"
                          color="#fff"
                          _hover={{ bgColor: 'primary.100' }}
                          rounded={'full'}
                        >
                          <Icon as={BsArrowUp} fontSize="24px" />
                        </Button>
                      </Box>
                      <Text fontSize="12px" textAlign="center">
                        NavGPT can make mistakes, Consider checking important
                        information.
                      </Text>
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack height="100%">
                <Stack borderRadius={'12px'}>
                  <Stack
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    borderRadius={'10px'}
                    marginBottom={'7px !important'}
                    spacing={0}
                    // flexWrap={'wrap'}
                    width="100%"
                  >
                    <Box
                      padding={'20px'}
                      direction={'row'}
                      width="100%"
                      rounded={'md'}
                    >
                      <Heading
                        w="100%"
                        fontSize={'22px'}
                        margin={'0 0 20px 0'}
                        alignItems={'center'}
                      >
                        Input Validation
                      </Heading>
                      <SimpleGrid columns={3} spacing={6}>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <PolarArea options={options} data={firstChart} />
                          </Stack>
                        </Box>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Bar data={secondChart} options={options} />
                          </Stack>
                        </Box>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Line data={thirdChart} options={options} />
                          </Stack>
                        </Box>
                      </SimpleGrid>
                    </Box>

                    <Box
                      padding={'20px'}
                      direction={'row'}
                      width="100%"
                      rounded={'md'}
                    >
                      <Heading
                        w="100%"
                        fontSize={'22px'}
                        margin={'0 0 20px 0'}
                        alignItems={'center'}
                      >
                        Output Validation
                      </Heading>
                      <SimpleGrid columns={3} spacing={6}>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <PolarArea data={fourthChart} options={options} />
                          </Stack>
                        </Box>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Bar data={fifthChart} options={options} />
                          </Stack>
                        </Box>
                        <Box
                          backgroundColor={'#fff'}
                          padding={6}
                          height={'100%'}
                        >
                          <Stack
                            width={'100%'}
                            display={'flex'}
                            height={'100%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Line data={sixthChart} options={options} />
                          </Stack>
                        </Box>
                      </SimpleGrid>
                    </Box>
                  </Stack>
                  <Stack>
                    <Stack
                      width={'100%'}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={5}
                    >
                      <Accordion
                        allowMultiple
                        defaultIndex={[0]} // Sets the first item open in each section
                        collapsible
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                Statistics
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} backgroundColor={'#FFFFFF'}>
                            <Stack gap={10}>
                              <SimpleGrid columns={2} spacing={6}>
                                <Box
                                  maxW="md"
                                  borderWidth="1px"
                                  borderRadius="lg"
                                  overflow="hidden"
                                  boxShadow="md"
                                >
                                  <Box
                                    bg="#1E86FF"
                                    color="white"
                                    textAlign="left"
                                    p={4}
                                  >
                                    Priority
                                  </Box>
                                  <Table variant="simple">
                                    <Tbody>
                                      {Object.entries(
                                        chartsData.stats.prompt
                                      ).map(([key, value]) => (
                                        <Tr key={key}>
                                          <Td
                                            textTransform="capitalize"
                                            textAlign="left"
                                          >
                                            {key.replace(/_/g, ' ')}
                                          </Td>
                                          <Td
                                            isNumeric={
                                              typeof value === 'number'
                                            }
                                            textAlign="right"
                                          >
                                            {value.toString()}
                                          </Td>
                                        </Tr>
                                      ))}
                                    </Tbody>
                                  </Table>
                                </Box>
                                <Box>
                                  <Bar data={statsChart} />
                                </Box>
                              </SimpleGrid>
                              <Stack width={'100%'}>
                                <Box
                                  borderWidth="1px"
                                  borderRadius="lg"
                                  overflow="hidden"
                                  boxShadow="md"
                                >
                                  <Box
                                    bg="#1E86FF"
                                    color="white"
                                    textAlign="left"
                                    p={4}
                                  >
                                    Response
                                  </Box>
                                  <Table
                                    variant="simple"
                                    size={'lg'}
                                    width={'100%'}
                                  >
                                    <Tbody>
                                      {Object.entries(
                                        chartsData.stats.response
                                      ).map(([key, value]) => (
                                        <Tr key={key}>
                                          <Td
                                            textTransform="capitalize"
                                            textAlign="left"
                                          >
                                            {key.replace(/_/g, ' ')}
                                          </Td>
                                          <Td
                                            isNumeric={
                                              typeof value === 'number'
                                            }
                                            textAlign="right"
                                          >
                                            {value.toString()}
                                          </Td>
                                        </Tr>
                                      ))}
                                    </Tbody>
                                  </Table>
                                </Box>
                              </Stack>
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </SideWrapper>
  );
};

export default Policy;
