import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Circle,
} from '@chakra-ui/react';
import React from 'react';

const CoreComponent = ({ item, selectedCore, setSelectedCore }) => {
  console.log(selectedCore, 'selectedCore');
  console.log(item, 'selectedCore Item');
  // return (
  //   <Button
  //     key={item?.id}
  //     background={
  //       item?.heading === selectedCore
  //         ? 'linear-gradient(135deg, #EBF4FF, #D6E9FF)'
  //         : '#F9FAFB'
  //     }
  //     border={
  //       item?.heading === selectedCore
  //         ? '2px solid #1E86FF'
  //         : '1px solid #E2E8F0'
  //     }
  //     borderRadius="lg"
  //     height="auto"
  //     width="100%"
  //     padding="35px 10px"
  //     boxShadow={
  //       item?.heading === selectedCore
  //         ? '0px 4px 12px rgba(30, 134, 255, 0.3)'
  //         : '0px 2px 6px rgba(0, 0, 0, 0.1)'
  //     }
  //     _hover={{
  //       bgColor: item?.heading === selectedCore ? '#EBF4FF' : '#f3f6fc',
  //       boxShadow: '0px 4px 12px rgba(30, 134, 255, 0.3)',
  //       transform: 'scale(1.03)',
  //       transition: 'all 0.2s ease-in-out',
  //     }}
  //     _active={{
  //       bgColor: '#f0f4ff',
  //       transform: 'scale(0.98)',
  //       boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.15)',
  //     }}
  //     onClick={() => {
  //       console.log(`Setting selected core to: ${item?.heading}`);
  //       setSelectedCore(item?.heading);
  //     }}
  //   >
  //     <Grid templateColumns="1fr" gap={4} alignItems="center">
  //       {/* Icon */}
  //       {item?.icon && (
  //         <Box display="flex" justifyContent="center">
  //           <Image
  //             src={item?.icon}
  //             alt={item.heading}
  //             height={8}
  //             width={8}
  //             draggable={false}
  //             color="#1E86FF"
  //           />
  //         </Box>
  //       )}

  //       {/* Heading */}
  //       <Heading
  //         fontSize="18px"
  //         fontWeight="600"
  //         color="#262626"
  //         textAlign="center"
  //         whiteSpace="normal" // Allows text to wrap within container
  //       >
  //         {item?.heading || 'No Heading'}
  //       </Heading>

  //       {/* Description */}
  //       <Text
  //         fontSize="14px"
  //         textAlign="center"
  //         color="#4A5568"
  //         whiteSpace="normal" // Allows text to wrap within container
  //       >
  //         {/* {item?.description || 'No Description'} */}
  //       </Text>
  //     </Grid>
  //   </Button>
  // );
  return (
    <Card
      role="group"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-4px)',
        boxShadow: 'lg',
        bg: 'gray.50',
      }}
      position="relative"
      height="100%"
      p="4"
    >
      <Flex direction="column" height="100%" justifyContent="space-between">
        <CardHeader transition="background-color 0.2s" pb="2">
          <Flex
            h="12"
            bg="#E8E8E9"
            rounded="lg"
            align="center"
            justify="left"
            mb="4"
            pl={'15px'}
          >
            {item?.icon && <Icon as={item?.icon} height={8} width={8} />}
          </Flex>
          <Text fontSize="lg" fontWeight="semibold" mb="1" noOfLines={3}>
            {item?.heading || 'No Heading'}
          </Text>
          <Text color="gray.500" fontSize="sm" noOfLines={3}>
            {item?.description || 'No Description'}
          </Text>
        </CardHeader>
        <CardBody
          pt="0"
          as={Flex}
          alignItems={'end'}
          justifyContent={'flex-end'}
        >
          <Flex align="center" fontSize="sm" color="gray.500">
            <Flex ml="auto" align="center" gap="2">
              <Circle size="2" bg="green.400" />
              <Text>Active</Text>
            </Flex>
          </Flex>
        </CardBody>
      </Flex>
      {/* <Box
        position="absolute"
        inset="0"
        opacity="0"
        transition="opacity 0.2s"
        _groupHover={{ opacity: 0.05 }}
        rounded="xl"
      /> */}
    </Card>
  );
};

export default CoreComponent;
