import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar, Line, PolarArea } from 'react-chartjs-2';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import { GET } from '../utilities/ApiProvider.js';

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 20,
  plugins: {
    legend: {
      position: 'top', // Display the legend at the top
      labels: {
        font: {
          size: 12, // Adjust font size for better visibility
        },
      },
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black', // Color of the data labels
      align: 'top', // Alignment of the data labels (e.g., 'top', 'bottom', 'middle')
      font: {
        weight: 'bold',
      },
    },
  },
};
// const labels3 = ['Prompts', 'Voice', 'PA', 'Co-Pilot'];
// export const data3 = {
//   labels: labels3,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [40, 50, 50, 60],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
//     },
//   ],
// };

// const labels4 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
// export const data4 = {
//   labels: labels4,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels5 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
// export const data5 = {
//   labels: labels5,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels6 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
// export const data6 = {
//   labels: labels6,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels7 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
// export const data7 = {
//   labels: labels7,
//   datasets: [
//     {
//       data: [70, 90, 80, 30],
//       backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
//     }
//   ],
// };

// const labels8 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
// export const data8 = {
//   labels: labels8,
//   datasets: [
//     {
//       data: [60, 80, 70, 20],
//       backgroundColor: ['#726681', '#9C9C9C', '#8B3805', '#4467BF'],
//     }
//   ],
// };

// const labels9 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
// export const data9 = {
//   labels: labels9,
//   datasets: [
//     {
//       data: [40, 30, 20, 40],
//       backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
//     }
//   ],
// };

// const labels10 = ['Validation', 'Met-Data', 'Linear', 'Privacy', 'Integrity', 'Encryption', 'Secure Access', 'Model Theft', 'Data Poisoning', 'Resilience'];
// export const data10 = {
//   labels: labels10,
//   datasets: [
//     {
//       data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//     {
//       data: [8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
//       backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
//     },
//     {
//       data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
//       backgroundColor: ['#1F9232', '#1F9232', '#1F9232', '#1F9232'],
//     },
//   ],
// };

const EnterpriseAiData = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [chartsData, setChartsData] = useState({
    _id: '675e05f7f1cd379d3026c1d7',
    execution_time: 6.48,
    input_results_raw: {
      Anonymize: 'PASS',
      BanTopics: 'FAIL',
      Bias: 'FAIL',
      InvisibleText: 'PASS',
      PromptInjection: 'PASS',
      Secrets: 'PASS',
      Sensitive: 'PASS',
      TokenLimit: 'PASS',
      Toxicity: 'PASS',
    },
    input_results_raw_new: {
      Anonymize: 'PASS_0.91',
      BanTopics: 'FAIL_0.77',
      Bias: 'FAIL_0.85',
      InvisibleText: 'PASS_0.88',
      PromptInjection: 'PASS_0.76',
      Secrets: 'PASS_0.95',
      Sensitive: 'PASS_0.93',
      TokenLimit: 'PASS_0.82',
      Toxicity: 'PASS_0.79',
    },
    input_results_sanitized: {},
    model_response_raw:
      "Your AI in the AWS cloud shows partial compliance with NIST AI RMF principles. The AI configurations lack comprehensive lifecycle management practices, which impacts compliance. However, there is an emphasis on explainability, governance, and continuous monitoring, which aligns with NIST's focus on trustworthiness and security. Overall, while there are areas of strength, improvements are needed for full compliance.",
    model_response_sanitized: '',
    output_results_raw: {
      Bias: 'FAIL',
      Deanonymize: 'PASS',
      MaliciousURLs: 'PASS',
      NoRefusal: 'PASS',
      Relevance: 'PASS',
      Sensitive: 'PASS',
      URLReachability: 'PASS',
    },
    output_results_raw_new: {
      Bias: 'FAIL_0.87',
      Deanonymize: 'PASS_0.92',
      MaliciousURLs: 'PASS_0.78',
      NoRefusal: 'PASS_0.89',
      Relevance: 'PASS_0.93',
      Sensitive: 'PASS_0.88',
      URLReachability: 'PASS_0.82',
    },
    output_results_sanitized: {},
    prompt:
      'how much our AI in AWS cloud is compliant with NIST AI RMF principles?',
    request_info: {
      browser: 'Python Requests',
      content_type: 'application/json',
      device: 'Other',
      full_url: 'http://167.172.153.181:7000/api/v1/llmops/analyze',
      http_method: 'POST',
      os: 'Other',
      request_headers: {
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate',
        Connection: 'keep-alive',
        'Content-Length': '38732',
        'Content-Type': 'application/json',
        Host: '167.172.153.181:7000',
        'User-Agent': 'python-requests/2.31.0',
      },
      request_timestamp: '2024-12-14 22:25:53',
      requester_ip: '137.184.129.214',
    },
    sanitization_flag: false,
    sanitized_prompt: '',
    stats: {
      prompt: {
        has_patterns: 'none',
        sentiment_nltk: 0,
        toxicity_label: 'non-toxic',
      },
      response: {
        has_patterns: 'none',
        refusal_similarity: false,
        relevance_to_prompt: 0.8199999928474426,
        sentiment_nltk: 0.86,
        toxicity_label: 'non-toxic',
      },
    },
  });
  const [firstChart, setFirstChart] = useState({
    labels: ['Anonymize', 'BanTopics', 'Bias'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [secondChart, setSecondChart] = useState({
    labels: ['InvisibleText', 'PromptInjection', 'Secrets'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [thirdChart, setThirdChart] = useState({
    labels: ['Sensitive', 'TokenLimit', 'Toxicity'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80],
        fill: false,
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        tension: 0.1,
      },
    ],
  });
  const [fourthChart, setFourthChart] = useState({
    labels: ['Bias', 'Deanonymize', 'MaliciousURLs'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [fifthChart, setFifthChart] = useState({
    labels: ['NoRefusal', 'Relevance', 'Sensitive'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [sixthChart, setSixthChart] = useState({
    labels: ['URLReachability'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65],
        fill: false,
        borderColor: ['rgb(255, 205, 86)'],
        tension: 0.1,
      },
    ],
  });
  const [statsChart, setStatsChart] = useState({
    labels: [],
    datasets: [],
  });

  // const [firstChart, setFirstChart] = useState({
  //   labels: ['After Sanitization', 'Before Sanitization'],
  //   datasets: [
  //     {
  //       data: [0, 0],
  //       backgroundColor: ['#4467BF', '#FB9522'],
  //     },
  //   ],
  // });
  // const [secondChart, setSecondChart] = useState({
  //   labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [thirdChart, setThirdChart] = useState({
  //   labels: ['Bias', 'No Refusal', 'Relevance'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [fourthChart, setFourthChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [fifthChart, setFifthChart] = useState({
  //   labels: ['Non-toxic'],
  //   datasets: [
  //     {
  //       data: [0],
  //       backgroundColor: ['#4467BF'],
  //     },
  //   ],
  // });
  // const [sixthChart, setSixthChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     }
  //   ],
  // });
  // const [seventhChart, setSeventhChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [eighthChart, setEighthChart] = useState({
  //   labels: ['Address', 'Email', 'None'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });

  // const getChartData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let res = await GET(`api/v1/llmops/graphs`)
  //     console.log(res)
  //     setChartsData(res)
  //   } catch (err) {
  //     toast({
  //       description: err.message,
  //       duration: 4000,
  //       isClosable: true,
  //       position: 'top-right',
  //       status: 'false'
  //     })
  //   }
  //   setIsLoading(false);
  // }

  // useEffect(() => {
  //   getChartData()
  //   return () => { }
  // }, [])

  // useEffect(() => {
  //   if (chartsData !== null) {
  //     setFirstChart({
  //       labels: ['After Sanitization', 'Before Sanitization'],
  //       datasets: [
  //         {
  //           data: [chartsData?.first_graph?.['After Sanitization'], chartsData?.first_graph?.['Before Sanitization']],
  //           backgroundColor: ['#4467BF', '#FB9522'],
  //         },
  //       ],
  //     });
  //     setSecondChart({
  //       labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
  //       datasets: [
  //         {
  //           data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //     setThirdChart({
  //       labels: ['Bias', 'No Refusal', 'Relevance'],
  //       datasets: [
  //         {
  //           data: [chartsData?.third_graph?.fail_series['Bias'], chartsData?.third_graph?.fail_series['NoRefusal'], chartsData?.third_graph?.fail_series['Relevance']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //     setFourthChart({
  //       labels: Object?.keys(chartsData?.fourth_graph?.stats_prompt_character_count),
  //       datasets: [
  //         {
  //           data: Object?.values(chartsData?.fourth_graph?.stats_prompt_character_count),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         },
  //         {
  //           data: Object?.values(chartsData?.fourth_graph?.stats_response_character_count),
  //           backgroundColor: '#FB9522',
  //           borderColor: '#FB9522',
  //         }
  //       ],
  //     });
  //     setFifthChart({
  //       labels: ['Non-toxic'],
  //       datasets: [
  //         {
  //           data: [chartsData?.fifth_graph?.toxicity_label_counts['non-toxic']],
  //           backgroundColor: ['#4467BF'],
  //         },
  //       ],
  //     });
  //     setSixthChart({
  //       labels: Object.keys(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
  //       datasets: [
  //         {
  //           data: Object.values(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         }
  //       ],
  //     });
  //     setSeventhChart({
  //       labels: Object.keys(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
  //       datasets: [
  //         {
  //           data: Object.values(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         },
  //         {
  //           data: Object.values(chartsData?.seventh_garph?.stats_response_sentiment_nltk),
  //           backgroundColor: '#FB9522',
  //           borderColor: '#FB9522',
  //         }
  //       ],
  //     });
  //     setEighthChart({
  //       labels: ['Address', 'Email', 'None'],
  //       datasets: [
  //         {
  //           data: [chartsData?.eighth_graph?.has_patterns_counts['address'], chartsData?.eighth_graph?.has_patterns_counts['email'], chartsData?.eighth_graph?.has_patterns_counts['none']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //   }
  //   return () => { }
  // }, [chartsData]);

  // return (

  //   <SideWrapper>
  //     <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
  //       {/* Current Location identifier */}
  //       <Stack
  //         margin={'0 0 30px 0'}
  //         direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
  //         <LocationHeader
  //           forcedText={'Enterprise AI, Data, and LLMOps'}
  //         />
  //         <NotificationIcon />
  //       </Stack>
  //       <Stack padding={'35px 25px'} borderRadius={'12px'} bgColor={'#fff'}>
  //         {
  //           isLoading ? <Spinner />
  //             :
  //             <Stack spacing={0} w="100%" h="100%" border={'1px solid #adadad'}>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'}>
  //                   <Heading fontSize={"19px"} textAlign="center" >Enterprise AI Utilization</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>AI/LLM Agents</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={firstChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Business Function</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={secondChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Risk</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={thirdChart} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">Input Validation</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Bias, Toxicity, Relevance, <br /> Sensitivity</Heading>
  //                   <Box p="5px">
  //                     {/* <Bar options={options} data={data4} /> */}
  //                     <Scatter options={options} data={fourthChart} />
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Hallucination, Ban Topic,<br /> Anonymize, HITL</Heading>
  //                   <Box p="15px" height="200px" display="flex" justifyContent="center">
  //                     {/* <Pie options={options} data={fifthChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Secrets, Prompt Injection, Invisible <br /> Text, Accuracy</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={sixthChart} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">Output Validation</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Scatter options={options} data={seventhChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px" height={'206px'} display="flex" justifyContent="center">
  //                     {/* <Pie options={options} data={eighthChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={data9} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">DataOps</Heading>
  //                 </Box>
  //                 <Box w="81%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Bar height={"60px"} options={options} data={data10} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //             </Stack>
  //         }
  //       </Stack>
  //     </Stack>
  //   </SideWrapper >
  // );

  const getChartData = async () => {
    setIsLoading(true);
    try {
      let res = await GET('api/v1/llmops/records', {}, 'third');
      console.log('Last Val', res[res.length - 1]);
      if (res && res.length > 0) {
        setChartsData(res[res.length - 1]);
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 4000,
        isClosable: true,
        position: 'top-right',
        status: 'false',
      });
    }
    setIsLoading(false);
  };

  const generateChartData = (labels, type, chart) => {
    const passData = [];
    const failData = [];
    const colors = { PASS: '#F57020', FAIL: '#1E86FF' };

    labels.forEach(label => {
      let valueStr;
      if (type === 'input') {
        valueStr = chartsData.input_results_raw_new[label];
      } else {
        valueStr = chartsData.output_results_raw_new[label];
      }

      const [status, value] = valueStr.split('_');
      if (status === 'PASS') {
        passData.push(parseFloat(value));
        failData.push(0); // Add 0 for "Fail" where it's "Pass"
      } else {
        passData.push(0); // Add 0 for "Pass" where it's "Fail"
        failData.push(parseFloat(value));
      }
    });

    return {
      labels: labels,
      datasets: [
        {
          label: 'Pass',
          data: passData,
          backgroundColor: colors.PASS,
        },
        {
          label: 'Fail',
          data: failData,
          backgroundColor: colors.FAIL,
        },
      ],
    };
  };

  useEffect(() => {
    setFirstChart(
      generateChartData(['Anonymize', 'BanTopics', 'Bias'], 'input')
    );
    setSecondChart(
      generateChartData(
        ['InvisibleText', 'PromptInjection', 'Secrets'],
        'input',
        'bar'
      )
    );
    setThirdChart(
      generateChartData(['Sensitive', 'TokenLimit', 'Toxicity'], 'input')
    );
    setFourthChart(
      generateChartData(['Bias', 'Deanonymize', 'MaliciousURLs'], 'output')
    );
    setFifthChart(
      generateChartData(
        ['NoRefusal', 'Relevance', 'Sensitive'],
        'output',
        'bar'
      )
    );
    setSixthChart(generateChartData(['URLReachability'], 'output'));
    
    if (chartsData && chartsData.stats && chartsData.stats.response) {
      const numericData = Object.entries(chartsData.stats.response)
        .filter(([key, value]) => typeof value === 'number')
        .reduce(
          (acc, [key, value]) => {
            acc.labels.push(key.replace(/_/g, ' ')); // Replace underscores with spaces
            acc.data.push(value);
            return acc;
          },
          { labels: [], data: [] }
        );

      setStatsChart({
        labels: numericData.labels,
        datasets: [
          {
            label: 'Response Stats',
            data: numericData.data,
            backgroundColor: [
              '#FFBD43',
              '#F57020',
              '#33D156',
              '#1E86FF',
              '#7487EA',
              '#B55BB4',
              '#D898F1',
              '#667085',
              '#E3766C',
              '#9DAFEA',
              '#F52020',
              '#33B3BC',
            ],
          },
        ],
      });
    }
  }, [chartsData]);


  useEffect(() => {
    getChartData();
    return () => {};
  }, []);

  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <LocationHeader
            forcedText={'Dashboard'}
            followingRoute={['Enterprise AI, Data, and LLMOps']}
          />
          <NotificationIcon />
        </Stack>
        <Stack padding={'10px 25px'} borderRadius={'12px'}>
          <Stack
            alignItems={'center'}
            justifyContent={'space-between'}
            borderRadius={'10px'}
            marginBottom={'7px !important'}
            spacing={0}
            // flexWrap={'wrap'}
            width="100%"
          >
            <Box padding={'20px'} direction={'row'} width="100%" rounded={'md'}>
              <Heading
                w="100%"
                fontSize={'22px'}
                margin={'0 0 20px 0'}
                alignItems={'center'}
              >
                Input Validation
              </Heading>
              <SimpleGrid columns={3} spacing={6}>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <PolarArea options={options} data={firstChart} />
                  </Stack>
                </Box>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Bar data={secondChart} options={options} />
                  </Stack>
                </Box>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Line data={thirdChart} options={options} />
                  </Stack>
                </Box>
              </SimpleGrid>
            </Box>

            <Box padding={'20px'} direction={'row'} width="100%" rounded={'md'}>
              <Heading
                w="100%"
                fontSize={'22px'}
                margin={'0 0 20px 0'}
                alignItems={'center'}
              >
                Output Validation
              </Heading>
              <SimpleGrid columns={3} spacing={6}>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    display={'flex'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <PolarArea data={fourthChart} options={options} />
                  </Stack>
                </Box>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    display={'flex'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Bar data={fifthChart} options={options} />
                  </Stack>
                </Box>
                <Box backgroundColor={'#fff'} padding={6} height={'100%'}>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Line data={sixthChart} options={options} />
                  </Stack>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
          <Stack>
            <Stack
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              gap={5}
            >
              <Accordion
                allowMultiple
                defaultIndex={[0]} // Sets the first item open in each section
                collapsible
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Statistics
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} backgroundColor={'#FFFFFF'}>
                    <Stack gap={10}>
                      <SimpleGrid columns={2}>
                        <Box
                          maxW="md"
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          boxShadow="md"
                        >
                          <Box
                            bg="#1E86FF"
                            color="white"
                            textAlign="left"
                            p={4}
                          >
                            Prompt
                          </Box>
                          <Table variant="simple">
                            <Tbody>
                              {Object.entries(chartsData.stats.prompt).map(
                                ([key, value]) => (
                                  <Tr key={key}>
                                    <Td
                                      textTransform="capitalize"
                                      textAlign="left"
                                    >
                                      {key.replace(/_/g, ' ')}
                                    </Td>
                                    <Td
                                      isNumeric={typeof value === 'number'}
                                      textAlign="right"
                                    >
                                      {value.toString()}
                                    </Td>
                                  </Tr>
                                )
                              )}
                            </Tbody>
                          </Table>
                        </Box>
                        <Box>
                          <Bar data={statsChart} />
                        </Box>
                      </SimpleGrid>
                      <Stack width={'100%'}>
                        <Box
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          boxShadow="md"
                        >
                          <Box
                            bg="#1E86FF"
                            color="white"
                            textAlign="left"
                            p={4}
                          >
                            Response
                          </Box>
                          <Table variant="simple" size={'lg'} width={'100%'}>
                            <Tbody>
                              {Object.entries(chartsData.stats.response).map(
                                ([key, value]) => (
                                  <Tr key={key}>
                                    <Td
                                      textTransform="capitalize"
                                      textAlign="left"
                                    >
                                      {key.replace(/_/g, ' ')}
                                    </Td>
                                    <Td
                                      isNumeric={typeof value === 'number'}
                                      textAlign="right"
                                    >
                                      {value.toString()}
                                    </Td>
                                  </Tr>
                                )
                              )}
                            </Tbody>
                          </Table>
                        </Box>
                      </Stack>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </SideWrapper>
  );
};

export default EnterpriseAiData;
