import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  useToast,
  Text,
} from '@chakra-ui/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar, Line, PolarArea } from 'react-chartjs-2';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import { GET } from '../utilities/ApiProvider.js';

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 20,
  plugins: {
    legend: {
      position: 'top', // Display the legend at the top
      labels: {
        font: {
          size: 12, // Adjust font size for better visibility
        },
      },
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black', // Color of the data labels
      align: 'top', // Alignment of the data labels (e.g., 'top', 'bottom', 'middle')
      font: {
        weight: 'bold',
      },
    },
  },
};

export const noLabelOptions = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 20,
  plugins: {
    legend: {
      display: false,
      position: 'top', // Display the legend at the top
      labels: {
        font: {
          size: 12, // Adjust font size for better visibility
        },
      },
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black', // Color of the data labels
      align: 'top', // Alignment of the data labels (e.g., 'top', 'bottom', 'middle')
      font: {
        weight: 'bold',
      },
    },
  },
};

// const labels3 = ['Prompts', 'Voice', 'PA', 'Co-Pilot'];
// export const data3 = {
//   labels: labels3,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [40, 50, 50, 60],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
//     },
//   ],
// };

// const labels4 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
// export const data4 = {
//   labels: labels4,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels5 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
// export const data5 = {
//   labels: labels5,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels6 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
// export const data6 = {
//   labels: labels6,
//   datasets: [
//     {
//       data: [20, 30, 30, 40],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [60, 70, 70, 80],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//   ],
// };

// const labels7 = ['Bias', 'Toxicity', 'Relevance', 'Sensitivity'];
// export const data7 = {
//   labels: labels7,
//   datasets: [
//     {
//       data: [70, 90, 80, 30],
//       backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
//     }
//   ],
// };

// const labels8 = ['Hallucination', 'HITL', 'Ban Topic', 'Anonymize'];
// export const data8 = {
//   labels: labels8,
//   datasets: [
//     {
//       data: [60, 80, 70, 20],
//       backgroundColor: ['#726681', '#9C9C9C', '#8B3805', '#4467BF'],
//     }
//   ],
// };

// const labels9 = ['Secrets', 'Injection', 'Invisibility', 'Accuracy'];
// export const data9 = {
//   labels: labels9,
//   datasets: [
//     {
//       data: [40, 30, 20, 40],
//       backgroundColor: ['#1F9232', '#FB9522', '#8B3805', '#000C71'],
//     }
//   ],
// };

// const labels10 = ['Validation', 'Met-Data', 'Linear', 'Privacy', 'Integrity', 'Encryption', 'Secure Access', 'Model Theft', 'Data Poisoning', 'Resilience'];
// export const data10 = {
//   labels: labels10,
//   datasets: [
//     {
//       data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
//       backgroundColor: ['#4467BF', '#4467BF', '#4467BF', '#4467BF'],
//     },
//     {
//       data: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60],
//       backgroundColor: ['#FB9522', '#FB9522', '#FB9522', '#FB9522'],
//     },
//     {
//       data: [8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
//       backgroundColor: ['#9C9C9C', '#9C9C9C', '#9C9C9C', '#9C9C9C'],
//     },
//     {
//       data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
//       backgroundColor: ['#1F9232', '#1F9232', '#1F9232', '#1F9232'],
//     },
//   ],
// };

const AgenticIndex = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [chartsData, setChartsData] = useState({
    _id: '675e05f7f1cd379d3026c1d7',
    execution_time: 6.48,
    input_results_raw: {
      Low: 'PASS',
      Moderate: 'FAIL',
      High: 'FAIL',
      Task_Completion_Rate: 'PASS',
      TUE: 'FAIL',
      CSS: 'PASS',
      SPI: 'FAIL',
      Error_Rate: 'FAIL',
      Response_Time: 'PASS',
      MCR: 'FAIL',
      Precision: 'PASS',
      Dependency: 'PASS',
      Accuracy: 'PASS',
      Relevance: 'PASS',
      Automation: 'PASS',
      Containment_Accuracy: 'PASS',
      Ethical_Impact: 'FAIL',
      Responsibility_Impact: 'FAIL',
      Security_Impact: 'FAIL',
      Utilization: 'FAIL',
      Cost: 'FAIL',
      Allocation: 'FAIL',
      Latecy: 'FAIL',
    },
    input_results_raw_new: {
      Low: 'PASS_0.91',
      Moderate: 'FAIL_0.77',
      High: 'FAIL_0.85',
      Task_Completion_Rate: 'PASS_0.88',
      TUE: 'FAIL_0.87',
      CSS: 'PASS_0.99',
      SPI: 'FAIL_0.87',
      Error_Rate: 'FAIL_0.87',
      Response_Time: 'PASS_0.62',
      MCR: 'FAIL_0.12',
      Precision: 'PASS_0.55',
      Dependency: 'PASS_0.87',
      Accuracy: 'PASS_0.15',
      Relevance: 'PASS_0.98',
      Automation: 'PASS_0.76',
      Containment_Accuracy: 'PASS_0.76',
      Ethical_Impact: 'FAIL_0.57',
      Responsibility_Impact: 'FAIL_0.12',
      Security_Impact: 'FAIL_0.49',
      Utilization: 'FAIL_0.12',
      Cost: 'FAIL_0.97',
      Allocation: 'FAIL_0.68',
      Latency: 'FAIL_0.76',
    },
    input_results_sanitized: {},
    model_response_raw:
      "Your AI in the AWS cloud shows partial compliance with NIST AI RMF principles. The AI configurations lack comprehensive lifecycle management practices, which impacts compliance. However, there is an emphasis on explainability, governance, and continuous monitoring, which aligns with NIST's focus on trustworthiness and security. Overall, while there are areas of strength, improvements are needed for full compliance.",
    model_response_sanitized: '',
    output_results_raw: {
      Dependency: 'PASS',
      Accuracy: 'PASS',
      Relevance: 'PASS',
      Automation: 'PASS',
      Containment_Accuracy: 'PASS',
      Ethical_Impact: 'FAIL',
      Responsibility_Impact: 'FAIL',
      Security_Impact: 'FAIL',
      Utilization: 'FAIL',
      Cost: 'FAIL',
      Allocation: 'FAIL',
      Latecy: 'FAIL',
    },
    output_results_raw_new: {
      Dependency: 'PASS_0.87',
      Accuracy: 'PASS_0.15',
      Relevance: 'PASS_0.98',
      Automation: 'PASS_0.76',
      Containment_Accuracy: 'PASS_0.76',
      Ethical_Impact: 'FAIL_0.57',
      Responsibility_Impact: 'FAIL_0.12',
      Security_Impact: 'FAIL_0.49',
      Utilization: 'FAIL_0.12',
      Cost: 'FAIL_0.97',
      Allocation: 'FAIL_0.68',
      Latency: 'FAIL_0.76',
    },
    output_results_sanitized: {},
    prompt:
      'how much our AI in AWS cloud is compliant with NIST AI RMF principles?',
    request_info: {
      browser: 'Python Requests',
      content_type: 'application/json',
      device: 'Other',
      full_url: 'http://167.172.153.181:7000/api/v1/llmops/analyze',
      http_method: 'POST',
      os: 'Other',
      request_headers: {
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate',
        Connection: 'keep-alive',
        'Content-Length': '38732',
        'Content-Type': 'application/json',
        Host: '167.172.153.181:7000',
        'User-Agent': 'python-requests/2.31.0',
      },
      request_timestamp: '2024-12-14 22:25:53',
      requester_ip: '137.184.129.214',
    },
    sanitization_flag: false,
    sanitized_prompt: '',
    stats: {
      prompt: {
        Compliance: 'PASS_0.91',
        Governance: 'FAIL_0.82',
        Detection: 'FAIL_0.49',
        Conformance: 'PASS_0.86',
        Latency: 'FAIL_0.76',
        Completion: 'PASS_0.78',
        Collaboration: 'FAIL_0.87',
        Availability: 'PASS_0.69',
        Recovery: 'FAIL_0.57',
      },
      response: {
        has_patterns: 'none',
        refusal_similarity: false,
        Bias: 0.78,
        Transparency: 0.8,
        Fairness: 0.5,
        Toxicity: 0.86,
        Relevance: 0.82,
        toxicity_label: 'non-toxic',
      },
    },
  });
  const [firstChart, setFirstChart] = useState({
    labels: ['Low', 'Moderate', 'High '],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [secondChart, setSecondChart] = useState({
    labels: ['Task_Completion_Rate', 'TUE', 'CSS', 'SPI', 'Error_Rate'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [thirdChart, setThirdChart] = useState({
    labels: [
      'Task_Completion_Rate',
      'Response_Time',
      'Error_Rate',
      'MCR',
      'Precision',
    ],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80],
        fill: false,
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        tension: 0.1,
      },
    ],
  });
  const [fourthChart, setFourthChart] = useState({
    labels: ['Dependency', 'Accuracy', 'Relevance', 'Automation'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [fifthChart, setFifthChart] = useState({
    labels: [
      'Containment_Accuracy',
      'Ethical_Impact',
      'Responsibility_Impact',
      'Security_Impact',
    ],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        borderWidth: 1,
      },
    ],
  });
  const [sixthChart, setSixthChart] = useState({
    labels: ['Utilization', 'Cost', 'Allocation', 'Latency'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65],
        fill: false,
        borderColor: ['rgb(255, 205, 86)'],
        tension: 0.1,
      },
    ],
  });
  const [statsChart, setStatsChart] = useState({
    labels: [],
    datasets: [],
  });
  const [statsChart2, setStatsChart2] = useState({
    labels: ['Compliance', 'Governance', 'Detection', 'Conformance'],
    datasets: [
      {
        label: 'value',
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
        ],
      },
    ],
  });
  const [statsChart3, setStatsChart3] = useState({
    labels: [
      'Latency',
      'Completion',
      'Collaboration',
      'Availability',
      'Recovery',
    ],
    datasets: [
      {
        label: 'My First Dataset',
        data: [65, 59, 80, 89, 58],
        fill: false,
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
        ],
        tension: 0.1,
      },
    ],
  });

  // const [firstChart, setFirstChart] = useState({
  //   labels: ['After Sanitization', 'Before Sanitization'],
  //   datasets: [
  //     {
  //       data: [0, 0],
  //       backgroundColor: ['#4467BF', '#FB9522'],
  //     },
  //   ],
  // });
  // const [secondChart, setSecondChart] = useState({
  //   labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [thirdChart, setThirdChart] = useState({
  //   labels: ['Bias', 'No Refusal', 'Relevance'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [fourthChart, setFourthChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [fifthChart, setFifthChart] = useState({
  //   labels: ['Non-toxic'],
  //   datasets: [
  //     {
  //       data: [0],
  //       backgroundColor: ['#4467BF'],
  //     },
  //   ],
  // });
  // const [sixthChart, setSixthChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     }
  //   ],
  // });
  // const [seventhChart, setSeventhChart] = useState({
  //   labels: [0, 1, 2, 3, 4],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Bias'], chartsData?.second_graph?.fail_series['NoRefusal'], chartsData?.second_graph?.fail_series['Relevance']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });
  // const [eighthChart, setEighthChart] = useState({
  //   labels: ['Address', 'Email', 'None'],
  //   datasets: [
  //     {
  //       data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //       backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //     },
  //   ],
  // });

  // const getChartData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let res = await GET(`api/v1/llmops/graphs`)
  //     console.log(res)
  //     setChartsData(res)
  //   } catch (err) {
  //     toast({
  //       description: err.message,
  //       duration: 4000,
  //       isClosable: true,
  //       position: 'top-right',
  //       status: 'false'
  //     })
  //   }
  //   setIsLoading(false);
  // }

  // useEffect(() => {
  //   getChartData()
  //   return () => { }
  // }, [])

  // useEffect(() => {
  //   if (chartsData !== null) {
  //     setFirstChart({
  //       labels: ['After Sanitization', 'Before Sanitization'],
  //       datasets: [
  //         {
  //           data: [chartsData?.first_graph?.['After Sanitization'], chartsData?.first_graph?.['Before Sanitization']],
  //           backgroundColor: ['#4467BF', '#FB9522'],
  //         },
  //       ],
  //     });
  //     setSecondChart({
  //       labels: ['Anonymize', 'Ban Topics', 'Prompt Inject'],
  //       datasets: [
  //         {
  //           data: [chartsData?.second_graph?.fail_series['Anonymize'], chartsData?.second_graph?.fail_series['BanTopics'], chartsData?.second_graph?.fail_series['PromptInject']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //     setThirdChart({
  //       labels: ['Bias', 'No Refusal', 'Relevance'],
  //       datasets: [
  //         {
  //           data: [chartsData?.third_graph?.fail_series['Bias'], chartsData?.third_graph?.fail_series['NoRefusal'], chartsData?.third_graph?.fail_series['Relevance']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //     setFourthChart({
  //       labels: Object?.keys(chartsData?.fourth_graph?.stats_prompt_character_count),
  //       datasets: [
  //         {
  //           data: Object?.values(chartsData?.fourth_graph?.stats_prompt_character_count),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         },
  //         {
  //           data: Object?.values(chartsData?.fourth_graph?.stats_response_character_count),
  //           backgroundColor: '#FB9522',
  //           borderColor: '#FB9522',
  //         }
  //       ],
  //     });
  //     setFifthChart({
  //       labels: ['Non-toxic'],
  //       datasets: [
  //         {
  //           data: [chartsData?.fifth_graph?.toxicity_label_counts['non-toxic']],
  //           backgroundColor: ['#4467BF'],
  //         },
  //       ],
  //     });
  //     setSixthChart({
  //       labels: Object.keys(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
  //       datasets: [
  //         {
  //           data: Object.values(chartsData?.sixth_graph?.stats_prompt_sentiment_nltk),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         }
  //       ],
  //     });
  //     setSeventhChart({
  //       labels: Object.keys(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
  //       datasets: [
  //         {
  //           data: Object.values(chartsData?.seventh_garph?.stats_prompt_sentiment_nltk),
  //           backgroundColor: '#4467BF',
  //           borderColor: '#4467BF',
  //         },
  //         {
  //           data: Object.values(chartsData?.seventh_garph?.stats_response_sentiment_nltk),
  //           backgroundColor: '#FB9522',
  //           borderColor: '#FB9522',
  //         }
  //       ],
  //     });
  //     setEighthChart({
  //       labels: ['Address', 'Email', 'None'],
  //       datasets: [
  //         {
  //           data: [chartsData?.eighth_graph?.has_patterns_counts['address'], chartsData?.eighth_graph?.has_patterns_counts['email'], chartsData?.eighth_graph?.has_patterns_counts['none']],
  //           backgroundColor: ['#4467BF', '#FB9522', '#9C9C9C'],
  //         },
  //       ],
  //     });
  //   }
  //   return () => { }
  // }, [chartsData]);

  // return (

  //   <SideWrapper>
  //     <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
  //       {/* Current Location identifier */}
  //       <Stack
  //         margin={'0 0 30px 0'}
  //         direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
  //         <LocationHeader
  //           forcedText={'Enterprise AI, Data, and LLMOps'}
  //         />
  //         <NotificationIcon />
  //       </Stack>
  //       <Stack padding={'35px 25px'} borderRadius={'12px'} bgColor={'#fff'}>
  //         {
  //           isLoading ? <Spinner />
  //             :
  //             <Stack spacing={0} w="100%" h="100%" border={'1px solid #adadad'}>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'}>
  //                   <Heading fontSize={"19px"} textAlign="center" >Enterprise AI Utilization</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>AI/LLM Agents</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={firstChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Business Function</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={secondChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} p="10px 0" textAlign={'center'}>Risk</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={thirdChart} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">Input Validation</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Bias, Toxicity, Relevance, <br /> Sensitivity</Heading>
  //                   <Box p="5px">
  //                     {/* <Bar options={options} data={data4} /> */}
  //                     <Scatter options={options} data={fourthChart} />
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Hallucination, Ban Topic,<br /> Anonymize, HITL</Heading>
  //                   <Box p="15px" height="200px" display="flex" justifyContent="center">
  //                     {/* <Pie options={options} data={fifthChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}>Secrets, Prompt Injection, Invisible <br /> Text, Accuracy</Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={sixthChart} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">Output Validation</Heading>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Scatter options={options} data={seventhChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px" height={'206px'} display="flex" justifyContent="center">
  //                     {/* <Pie options={options} data={eighthChart} /> */}
  //                   </Box>
  //                 </Box>
  //                 <Box w="27%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Bar options={options} data={data9} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //               <Stack direction={'row'} spacing={0}>
  //                 <Box w="19%" display={'flex'} alignItems={'center'} justifyContent={'center'} borderTop={"1px solid #adadad"} mt="-1px">
  //                   <Heading fontSize={"19px"} textAlign="center">DataOps</Heading>
  //                 </Box>
  //                 <Box w="81%" borderLeft={'1px solid #adadad'} borderBottom={'1px solid #adadad'}>
  //                   <Heading fontSize={"16px"} borderBottom={"1px solid #adadad"} borderTop={"1px solid #adadad"} mt="20px" p="10px 0" textAlign={'center'}></Heading>
  //                   <Box p="15px">
  //                     {/* <Bar height={"60px"} options={options} data={data10} /> */}
  //                   </Box>
  //                 </Box>
  //               </Stack>
  //             </Stack>
  //         }
  //       </Stack>
  //     </Stack>
  //   </SideWrapper >
  // );

  const getChartData = async () => {
    setIsLoading(true);
    try {
      let res = await GET('api/v1/llmops/records', {}, 'third');
      console.log('Last Val', res[res.length - 1]);
      if (res && res.length > 0) {
        setChartsData(res[res.length - 1]);
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 4000,
        isClosable: true,
        position: 'top-right',
        status: 'false',
      });
    }
    setIsLoading(false);
  };

  const generateChartData = (labels, type, chart) => {
    const passData = [];
    const failData = [];
    const colors = { PASS: '#F57020', FAIL: '#1E86FF' };

    const formattedLabels = labels.map(label => label.replace(/_/g, ' ')); // Replace underscores with spaces

    labels.forEach(label => {
      let valueStr;
      if (type === 'prompt') {
        valueStr = chartsData?.stats?.prompt?.[label];
      } else if (type === 'input') {
        valueStr = chartsData?.input_results_raw_new?.[label];
      } else {
        valueStr = chartsData?.output_results_raw_new?.[label];
      }

      if (!valueStr || !valueStr.includes('_')) {
        console.error(`Missing or invalid value for label: ${label}`);
        passData.push(0);
        failData.push(0);
        return;
      }

      const [status, value] = valueStr.split('_');
      if (status === 'PASS') {
        passData.push(parseFloat(value));
        failData.push(0);
      } else {
        passData.push(0);
        failData.push(parseFloat(value));
      }
    });

    return {
      labels: formattedLabels, // Use the formatted labels with spaces
      datasets: [
        {
          label: 'Pass',
          data: passData,
          backgroundColor: colors.PASS,
        },
        {
          label: 'Fail',
          data: failData,
          backgroundColor: colors.FAIL,
        },
      ],
      tension: 0.4,
    };
  };

  useEffect(() => {
    setFirstChart(generateChartData(['Low', 'Moderate', 'High'], 'input'));
    setSecondChart(
      generateChartData(
        ['Task_Completion_Rate', 'TUE', 'CSS', 'SPI', 'Error_Rate'],
        'input',
        'bar'
      )
    );
    setThirdChart(
      generateChartData(
        [
          'Task_Completion_Rate',
          'Response_Time',
          'Error_Rate',
          'MCR',
          'Precision',
        ],
        'input'
      )
    );
    setFourthChart(
      generateChartData(
        ['Dependency', 'Accuracy', 'Relevance', 'Automation'],
        'output'
      )
    );
    setFifthChart(
      generateChartData(
        [
          'Containment_Accuracy',
          'Ethical_Impact',
          'Responsibility_Impact',
          'Security_Impact',
        ],
        'output',
        'bar'
      )
    );
    setSixthChart(
      generateChartData(
        ['Utilization', 'Cost', 'Allocation', 'Latency'],
        'output'
      )
    );
    setStatsChart2(
      generateChartData(
        ['Compliance', 'Governance', 'Detection', 'Conformance'],
        'prompt'
      )
    );
    setStatsChart3(
      generateChartData(
        ['Latency', 'Completion', 'Collaboration', 'Availability', 'Recovery'],
        'prompt'
      )
    );
  }, [chartsData]);

  useEffect(() => {
    if (chartsData && chartsData.stats && chartsData.stats.response) {
      const numericData = Object.entries(chartsData.stats.response)
        .filter(([key, value]) => typeof value === 'number')
        .reduce(
          (acc, [key, value]) => {
            acc.labels.push(key.replace(/_/g, ' ')); // Replace underscores with spaces
            acc.data.push(value);
            return acc;
          },
          { labels: [], data: [] }
        );

      setStatsChart({
        labels: numericData.labels,
        datasets: [
          {
            label: 'Response Stats',
            data: numericData.data,
            backgroundColor: [
              '#FFBD43',
              '#F57020',
              '#33D156',
              '#1E86FF',
              '#7487EA',
              '#B55BB4',
              '#D898F1',
              '#667085',
              '#E3766C',
              '#9DAFEA',
              '#F52020',
              '#33B3BC',
            ],
          },
        ],
      });
    }
  }, [chartsData]);

  // useEffect(() => {
  //   getChartData();
  // }, []);

  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <LocationHeader
            forcedText={'Dashboard'}
            followingRoute={['Agentic Workflow Oversight & Optimization ']}
          />
          <NotificationIcon />
        </Stack>
        <Stack padding={'10px 25px'} borderRadius={'12px'}>
          <Stack
            alignItems={'center'}
            justifyContent={'space-between'}
            borderRadius={'10px'}
            marginBottom={'7px !important'}
            spacing={0}
            // flexWrap={'wrap'}
            width="100%"
          >
            <Box padding={'20px'} direction={'row'} width="100%" rounded={'md'}>
              <SimpleGrid columns={3} spacing={6}>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    Goal/Outcome Alignment
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <PolarArea options={options} data={firstChart} />
                  </Stack>
                </Box>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    Multi-Agent Decision Validation
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Bar data={secondChart} options={noLabelOptions} />
                  </Stack>
                </Box>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    Single Agent Decision Validation
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Line data={thirdChart} options={noLabelOptions} />
                  </Stack>
                </Box>
              </SimpleGrid>
            </Box>

            <Box padding={'20px'} direction={'row'} width="100%" rounded={'md'}>
              <SimpleGrid columns={3} spacing={6}>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    HITL Reduction/Efficacy
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <PolarArea data={fourthChart} options={options} />
                  </Stack>
                </Box>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    Graceful Interruption of Harmful Outcomes
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Bar data={fifthChart} options={noLabelOptions} />
                  </Stack>
                </Box>
                <Box
                  backgroundColor={'#fff'}
                  padding={6}
                  height={'100%'}
                  rounded={'lg'}
                  shadow={'md'}
                  _hover={{
                    shadow: 'lg',
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Text
                    textTransform={'capitalize'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'18px'}
                    mb={3}
                  >
                    GPU Optimization
                  </Text>
                  <Stack
                    width={'100%'}
                    display={'flex'}
                    height={'86%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Line data={sixthChart} options={noLabelOptions} />
                  </Stack>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
          <Stack
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            gap={8}
            alignItems="center"
          >
            <SimpleGrid
              columns={[1, null, 2]}
              spacing={10}
              width="100%"
              maxW="1200px"
            >
              {/* First Chart */}
              <Box
                maxW="lg"
                padding={6}
                height={'400px'}
                backgroundColor={'#fff'}
                rounded={'lg'}
                shadow={'md'}
                _hover={{
                  shadow: 'lg',
                  transform: 'scale(1.05)',
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Text textTransform={'capitalize'} textAlign={'center'} mb={3}>
                  <strong style={{ fontSize: '18px' }}>
                    AI Risk & Governance Maturity
                  </strong>
                  <br /> NIST AI-RMF, ISO 42001/23894, OECD AI, OWASP LLM, EU AI
                  Act, U.S Executive Order, NIST Zero-Trust
                </Text>
                <Stack
                  width={'100%'}
                  display={'flex'}
                  height={'86%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <PolarArea options={options} data={statsChart2} />
                </Stack>
              </Box>

              {/* Second Chart */}
              <Box
                maxW="lg"
                backgroundColor={'#fff'}
                padding={6}
                height={'400px'}
                rounded={'lg'}
                shadow={'md'}
                _hover={{
                  shadow: 'lg',
                  transform: 'scale(1.05)',
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Text
                  textTransform={'capitalize'}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  fontSize={'18px'}
                  mb={3}
                >
                  Responsible & Ethical AI Index
                </Text>
                <Stack
                  width={'100%'}
                  display={'flex'}
                  height={'86%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Bar data={statsChart} options={options} />
                </Stack>
              </Box>
            </SimpleGrid>

            {/* Single/Multi-Agent Chart */}
            <Box
              maxW="2xl"
              padding={6}
              backgroundColor="#fff"
              height="400px"
              width="100%"
              rounded={'lg'}
              shadow={'md'}
              mt={6}
              _hover={{
                shadow: 'lg',
                transform: 'scale(1.05)',
                transition: 'all 0.2s ease-in-out',
              }}
            >
              <Text
                textTransform="capitalize"
                textAlign="center"
                fontWeight="bold"
                mb={3}
                fontSize="18px"
              >
                Single/Multi-Agent Performance
              </Text>
              <Stack
                width="100%"
                display="flex"
                height="86%"
                alignItems="center"
                justifyContent="center"
              >
                <Line data={statsChart3} options={noLabelOptions} />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </SideWrapper>
  );
};

export default AgenticIndex;
