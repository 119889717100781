'use client';

import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiMenu, FiLifeBuoy, FiPower } from 'react-icons/fi';
import {
  RiHome3Line,
  RiFileCloudLine,
  RiStackLine,
  RiSearchLine,
} from 'react-icons/ri';
import {
  BsPhoneLandscape,
  BsChevronUp,
  BsChevronDown,
  BsChatDots,
} from 'react-icons/bs';
import Logo from '../../assets/images/logoF.png';
import { GrTest } from 'react-icons/gr';
import { BiPieChartAlt2, BiUserCircle } from 'react-icons/bi';
import {
  AiOutlineFileText,
  AiOutlineProfile,
  AiOutlineDisconnect,
} from 'react-icons/ai';
import policy from '../../assets/images/companies/policy.png';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, pushNotificationState } from '../../reducers/useReducers';
import { FaBell, FaChartLine } from 'react-icons/fa';
import { useContext } from 'react';

const LinkItems = [
  { name: 'Dashboard', url: 'dashboard', icon: RiHome3Line },
  { name: 'Cloud Onboarding', url: 'cloud-onboarding', icon: RiFileCloudLine },
  // { name: 'Desired Outcomes', url: 'desired-outcomes', icon: RiStackLine },
  {
    name: 'AI Autonomous Segmentation',
    url: 'ai-autonomous-segmentation',
    icon: RiFileCloudLine,
  },
  {
    name: 'Policy Generator',
    url: 'policy-generator',
    icon: AiOutlineDisconnect,
  },
  { name: 'NavGPT - Ask the Advisor', url: 'navGPT', icon: BsChatDots },
  {
    name: 'GPU/DPU Optimization',
    url: 'gpu-dpu-optimization',
    icon: AiOutlineDisconnect,
  },
  { name: 'Solution Builder', url: 'solution-builder', icon: BiPieChartAlt2 },
  { name: 'Summary Report', url: '', icon: AiOutlineProfile },
  // { name: 'Risk/Cost Impact Simulation', url: 'risk-simulation', icon: FiLifeBuoy },
  // { name: 'Cloud/Business Impact Simulation', url: 'business-simulation', icon: FiLifeBuoy },
  {
    name: 'Third-Party Integrations',
    url: 'third-party-integration',
    icon: AiOutlineDisconnect,
  },
  { name: 'Administration', url: '', icon: BiUserCircle },
  { name: 'Logging', url: '', icon: AiOutlineFileText },
  {
    name: 'Enterprise AI, Data, and LLMOps',
    url: 'enterprise-ai-data',
    icon: FaChartLine,
  },
  {
    name: 'Agentic Ai Risk & Workflow Optimization Hub',
    url: 'agentic-ai-risk',
    icon: AiOutlineDisconnect,
  },
  {
    name: 'Agentic Index',
    url: 'agentic-workflow-oversight',
    icon: FaChartLine,
  },
  { name: 'Notifications', url: 'notifications', icon: FaBell },
  { name: 'Test-Demo', url: 'test', icon: GrTest },
  { name: 'Logout', url: '', icon: FiPower },
  // { name: 'Devices Applications', url: 'device-application', icon: BsPhoneLandscape },
  // { name: 'Discover Inventory', url: 'discover-inventory', icon: RiSearchLine },
];

export default function SimpleSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        dispatch={dispatch}
        onClose={() => onClose}
        display={{ base: 'none', xl: 'block' }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent dispatch={dispatch} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', xl: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {/* Content */}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, dispatch, ...rest }) => {
  const pushNotificationConnectionRef = useSelector(
    state => state.pushNotificationConnectionRef
  );
  return (
    <Box
      bg={'primary.100'}
      borderRight="1px"
      borderRightColor={useColorModeValue('transparent', 'transparent')}
      w={{ base: 'full', md: '350px' }}
      pos="fixed"
      h="full"
      overflow="auto"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" my={5} justifyContent="center">
        <Image
          src={Logo}
          w={'100px'}
          margin={'30px 0px'}
          alignItems={'center'}
        />
        <CloseButton display={{ base: 'flex', xl: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem
          onClick={() => {
            if (link.name === 'Logout') {
              if (pushNotificationConnectionRef) {
                if (pushNotificationConnectionRef.deactivate) {
                  pushNotificationConnectionRef.deactivate(); // Deactivates the connection
                  console.log('Push notification connection deactivated.');
                }

                // Explicitly clear any existing reconnect logic
                pushNotificationConnectionRef.reconnectDelay = 0; // Disable further reconnection attempts
                pushNotificationConnectionRef.onConnect = null; // Remove onConnect handler
                pushNotificationConnectionRef.onStompError = null; // Remove onStompError handler
              }
              dispatch(pushNotificationState(null));
              dispatch(logout());
              console.log('User logged out.');
            }
          }}
          key={link.name}
          icon={link.icon}
          url={link.url}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, url, children, ...rest }) => {
  const location = useLocation();
  return (
    <Box
      mt={'5px'}
      as={ReactLink}
      to={`/${url}`}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      h="500px"
      overflowX={'hidden !important'}
      overflow={'scroll'}
      {...rest}
    >
      <Flex
        align="center"
        justifyContent={'space-between'}
        py="2"
        px="4"
        mx="3"
        borderRadius="lg"
        role="group"
        transition={'0.2s'}
        cursor="pointer"
        bgColor={
          location.pathname === `/${url}` ? 'secondary.100' : 'transparent'
        }
        color={location.pathname === `/${url}` ? '#fff' : '#8A959E'}
        _hover={{
          bg: 'secondary.100',
          color: 'white',
        }}
        {...rest}
      >
        <Stack direction={'row'} alignItems={'center'}>
          {icon && (
            <Icon
              mr="2"
              fontSize="18"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          <Text fontSize={'15px'}>{children}</Text>
        </Stack>
        <Icon
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={location.pathname === `/${url}` ? BsChevronUp : BsChevronDown}
        />
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      className="toggleBtn"
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image src={Logo} w={'80px'} margin={'30px 0'} />
    </Flex>
  );
};
