import {
  Box,
  Heading,
  Icon,
  Stack,
  Text,
  Button,
  FormLabel,
  Input,
  filter,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import LocationHeader from '../components/LocationHeader/index.js';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import CloudOptionButton from '../components/CloudComponents/CloudOptionButton.js';
import { useState, useEffect } from 'react';
import HostCloudButton from '../components/CloudComponents/HostCloudButton.js';
import { useToast } from '@chakra-ui/react';
import CloudLogin from '../components/CloudComponents/CloudLogin.js';
import { FaBell } from 'react-icons/fa';
import {
  cloudOptions,
  hostCloud,
  peer1,
  peer2,
  getCloudOptionId,
  fetchSupportedVendors,
  fetchVendorConfigurations,
} from '../utilities/cloudOnBoardingData.js';
import SpacedStack from '../components/SpacedStack/index.js';
import { HiUserAdd } from 'react-icons/hi';
import { ExpiredToken } from '../components/ExpiredToken/ExpiredToken.js';
import { AiOutlinePlus } from 'react-icons/ai';
import AdditionalPeers from '../components/CloudComponents/AdditionalPeers.js';
import { GET, POST, PUT } from '../utilities/ApiProvider.js';
import axios from 'axios';
import { BaseURL } from '../utilities/config.js';
import { Form } from 'antd';
import sap from '../assets/images/sap.png';
import salesForce from '../assets/images/salesForce.png';
import oracle from '../assets/images/oracle.png';
import { BiReset } from 'react-icons/bi';

export default function CloudOnboarding() {
  const navigation = useNavigate();
  const [user, setUser] = useState({});
  const [selectedCloudOption, setSelectedCloudOption] = useState('');
  const [apiData, setApiData] = useState(null);
  const apiUrl = 'https://144.126.248.16.nip.io/api/v1/tenant/supported-vendor';
  const [hostData, setHostData] = useState([]);
  const [optionsList] = useState(['Host Cloud', 'Peer 1', 'Peer 2']);
  const [displayFields, setDisplayFields] = useState({});

  const [value, setValue] = useState({
    name: '',
    iconPath: '',
    supportedVendorId: '',
    configurations: {
      host: {
        credentials: {
          configuraitonId: '',
          username: '',
          password: '',
          clientSecret: '',
          clientId: '',
          tenantId: '',
          subscriptionId: '',
          accessToken: '',
          discoveryEnabled: false,
          variables: {
            rgn: '',
            format: '',
          },
          configurationFiles: {
            rgn: '',
            name: '',
            content: '',
          },
        },
      },
    },
  });

  const payloadSkeleton = val => {
    return {
      configurations: {},
    };
  };
  const [selectedHostOption, setSelectedHostOption] = useState(null);
  const [selectedPeer1Option, setSelectedPeer1Option] = useState(null);
  const [selectedPeer2Option, setSelectedPeer2Option] = useState(null);
  const [data, setData] = useState([]);
  const selector = useSelector(state => state);
  const token =
    'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJrM2tTeVZoZmhuTDVRZjliWTNVaUVzQk5fX0hvVG1nb1J5bHpIaGEwdkRVIn0.eyJleHAiOjE2OTUyMDE1MjYsImlhdCI6MTY5NTIwMTIyNiwianRpIjoiYzI4OGI0MzktNmNjOC00MGQ1LWE0ZmMtOGQ5OWVhODMwMDc2IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mb3ItdGhlLmJpei9yZWFsbXMvY2lwaGVyc3RhY2siLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNjQ3MmE2YjEtZmI2ZC00YTg1LWI0ZjAtYTlkMjkwY2MyZjcxIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiY2lwaGVyc3RhY2stY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImY0YzU4NTQ1LWMyYmUtNGU4My1hM2YxLTYxOGUxZmU2YTA2MSIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo3ODAwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWNpcGhlcnN0YWNrIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJzaWQiOiJmNGM1ODU0NS1jMmJlLTRlODMtYTNmMS02MThlMWZlNmEwNjEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkNpcGhlcnN0YWNrIFVzZXIiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJjaXBoZXJzdGFja3VzZXIiLCJnaXZlbl9uYW1lIjoiQ2lwaGVyc3RhY2siLCJmYW1pbHlfbmFtZSI6IlVzZXIifQ.MiyxA3ncUqnnE1w5mcCNcP7CBoNA-hcqbH68KEYgKMGY4qQA-kPsQeCtJZz6u7hClS9v9Tz8sGbvbKlnZQUteKYGMIaW5CERT5sW_rF589_rIknq_rXsCEAKqgEcR47UxTS7OSEcn0sJ_I1fegNzXhmtUIvbjOCpmmhWKlamI96Ry7pl57POT9ewNdcFwi74TF6CYSmVI5tVXaybbqjx7ycZomeSpJtH-0z2ZGb4e3zX_YT-VpIwYrMSDZvgqQzm-NFH82xyE5jR2tC5X8LQfU42tR03Qak3f0ML1B9Tg__39VLgiruy-KJQVO_lKkSSJ0wOBNpXNJdkbtHKMAzulQ';
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const navigate = useNavigate();
  const toast = useToast();

  const getData = async () => {
    let res = await fetchSupportedVendors(errorMsg => {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: errorMsg,
      });
    });

    // Remove items with specific keys
    res = res.filter(
      val => !['WEBEX', 'MSTEAMS', 'ZOOM', 'OKTA'].includes(val.key)
    );

    res.map(item => {
      item['disabled'] = false;

      if (item.key == 'AWS') {
        item['displayFields'] = {
          username: true,
          clientId: true,
          clientSecret: true,
          variables: true,
          region: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'AZURE' || item.key === 'MSTEAMS') {
        item['displayFields'] = {
          clientId: true,
          clientSecret: true,
          subscriptionId: true,
          tenantId: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'ZOOM') {
        item['displayFields'] = {
          clientId: true,
          clientSecret: true,
          tenantId: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'WEBEX') {
        item['displayFields'] = {
          accessToken: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key == 'GCP') {
        item['displayFields'] = {
          configurationFiles: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'OKTA') {
        item['displayFields'] = {
          baseUrl: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'ORACLE') {
        item['displayFields'] = {
          username: true,
          password: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'SALESFORCE') {
        item['displayFields'] = {
          username: true,
          password: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'PALOALTO') {
        item['displayFields'] = {
          username: true,
          password: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'SAP') {
        item['displayFields'] = {
          username: true,
          password: true,
          variables: true,
          discoveryEnabled: true,
        };
      } else if (item.key === 'CISCO') {
        item['displayFields'] = {
          username: true,
          password: true,
          variables: true,
          discoveryEnabled: true,
        };
      }
    });

    console.log('Resultss', res);

    setHostData(res); // Ensure hostData is an array
  };

  const getVenderManagement = async () => {
    let res = await fetchVendorConfigurations(errorMsg => {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: errorMsg,
      });
    });

    if (res == null || res == undefined || res == '') {
      res = {
        configurations: {},
      };
    }
    setData(res);
  };

  const [filterData, setFilterData] = useState({});
  const [mainVal, setMainVal] = useState({
    rgn1: '',
    format: '',
    rgn2: '',
    name: '',
    content: '',
  });

  const getMainData = async e => {
    const { name, value, files } = e.target;
    console.log(name, value);

    if (files) {
      const file = files[0]; // Assuming you handle only one file at a time

      if (file) {
        const reader = new FileReader();

        reader.onload = readerEvent => {
          const binaryImageData = readerEvent.target.result; // This contains the raw binary data of the image as an ArrayBuffer

          // Convert the binary image data to a Base64-encoded string using a DataView
          const dataView = new DataView(binaryImageData);
          let base64ImageString = '';

          for (let i = 0; i < dataView.byteLength; i++) {
            base64ImageString += String.fromCharCode(dataView.getUint8(i));
          }

          base64ImageString = btoa(base64ImageString);

          // Update only the "content" field in mainVal with the string representation
          setMainVal({
            ...mainVal,
            content: base64ImageString, // This sets it as a Base64-encoded string
          });
        };

        reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
      }
    } else {
      // If it's not a file input, update other fields as usual
      setMainVal({
        ...mainVal,
        [name]: value,
      });
    }
  };

  const sendData = async () => {
    if (
      !mainVal.name ||
      !mainVal.rgn1 ||
      !mainVal.rgn2 ||
      !mainVal.format ||
      !mainVal.content
    ) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Please fill all the fields',
      });
      return;
    }
    let data = filterData;
    data.configurations.host.configurationFiles = {
      rgn: mainVal.rgn1,
      name: mainVal.name,
      content: mainVal.content,
    };

    data.configurations.host.variables = {
      rgn: mainVal.rgn2,
      format: mainVal.format,
    };
    console.log('data', data);
    const res = await POST('api/v1/tenant/vendor/', data);
    console.log('res', res);
    try {
      if (res?.configurations) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: 'Success',
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: error?.message,
      });
    }
  };

  const [filterData2, setFilterData2] = useState({});
  const [mainVal2, setMainVal2] = useState({
    rgn1: '',
    format: '',
    rgn2: '',
    name: '',
    content: '',
  });

  const getMainData2 = async e => {
    const { name, value, files } = e.target;
    console.log(name, value);

    if (files) {
      const file = files[0]; // Assuming you handle only one file at a time

      if (file) {
        const reader = new FileReader();

        reader.onload = readerEvent => {
          const binaryImageData = readerEvent.target.result; // This contains the raw binary data of the image as an ArrayBuffer

          // Convert the binary image data to a Base64-encoded string using a DataView
          const dataView = new DataView(binaryImageData);
          let base64ImageString = '';

          for (let i = 0; i < dataView.byteLength; i++) {
            base64ImageString += String.fromCharCode(dataView.getUint8(i));
          }

          base64ImageString = btoa(base64ImageString);

          // Update only the "content" field in mainVal with the string representation
          setMainVal2({
            ...mainVal2,
            content: base64ImageString, // This sets it as a Base64-encoded string
          });
        };

        reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
      }
    } else {
      // If it's not a file input, update other fields as usual
      setMainVal2({
        ...mainVal2,
        [name]: value,
      });
    }
  };

  const sendData2 = async () => {
    if (
      !mainVal2.name ||
      !mainVal2.rgn1 ||
      !mainVal2.rgn2 ||
      !mainVal2.format ||
      !mainVal2.content
    ) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Please fill all the fields',
      });
      return;
    }
    let data = filterData2;
    data.configurations.host.configurationFiles = {
      rgn: mainVal2.rgn1,
      name: mainVal2.name,
      content: mainVal2.content,
    };

    data.configurations.host.variables = {
      rgn: mainVal2.rgn2,
      format: mainVal2.format,
    };
    console.log('data', data);
    const res = await POST('api/v1/tenant/vendor/', data);
    console.log('res', res);
    try {
      if (res?.configurations) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: 'Success',
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: error?.message,
      });
    }
  };

  const [filterData3, setFilterData3] = useState({});
  const [mainVal3, setMainVal3] = useState({
    rgn1: '',
    format: '',
    rgn2: '',
    name: '',
    content: '',
  });

  const getMainData3 = async e => {
    const { name, value, files } = e.target;
    console.log(name, value);

    if (files) {
      const file = files[0]; // Assuming you handle only one file at a time

      if (file) {
        const reader = new FileReader();

        reader.onload = readerEvent => {
          const binaryImageData = readerEvent.target.result; // This contains the raw binary data of the image as an ArrayBuffer

          // Convert the binary image data to a Base64-encoded string using a DataView
          const dataView = new DataView(binaryImageData);
          let base64ImageString = '';

          for (let i = 0; i < dataView.byteLength; i++) {
            base64ImageString += String.fromCharCode(dataView.getUint8(i));
          }

          base64ImageString = btoa(base64ImageString);

          // Update only the "content" field in mainVal with the string representation
          setMainVal3({
            ...mainVal3,
            content: base64ImageString, // This sets it as a Base64-encoded string
          });
        };

        reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
      }
    } else {
      // If it's not a file input, update other fields as usual
      setMainVal3({
        ...mainVal3,
        [name]: value,
      });
    }
  };

  const sendData3 = async () => {
    if (
      !mainVal3.name ||
      !mainVal3.rgn1 ||
      !mainVal3.rgn2 ||
      !mainVal3.format ||
      !mainVal3.content
    ) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Please fill all the fields',
      });
      return;
    }
    let data = filterData3;
    data.configurations.host.configurationFiles = {
      rgn: mainVal3.rgn1,
      name: mainVal3.name,
      content: mainVal3.content,
    };

    data.configurations.host.variables = {
      rgn: mainVal3.rgn2,
      format: mainVal3.format,
    };
    console.log('data', data);
    const res = await POST('api/v1/tenant/vendor/', data);
    console.log('res', res);
    try {
      if (res?.configurations) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: 'Success',
        });
      }
    } catch (error) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: error?.message,
      });
    }
  };

  const findData = (hostCloudOption, ind) => {
    var credentialsPayload = {
      discoveryEnabled: false,
      supportedVendorId: hostCloudOption,
      credentials: {
        username: '',
        clientId: '',
        clientSecret: '',
        accessToken: '',
        baseUrl: '',
        tenantId: '',
        subscriptionId: '',
        region: '',
        configurationFiles: {},
        variables: {},
      },
    };

    let cloudId = getCloudOptionId(selectedCloudOption);
    if (
      data?.configurations[cloudId] === undefined ||
      data?.configurations[cloudId] == null
    ) {
      console.log(
        'findData - No cloud config found against cloudId' +
          cloudId +
          '. Assigning sekeleton payload'
      );
      data.configurations[cloudId] = credentialsPayload;
    } else {
      console.log(
        'findData - Cloud config found against cloudId' + cloudId + '.'
      );
    }

    var credentials =
      data?.configurations[cloudId]?.supportedVendorId == hostCloudOption
        ? data?.configurations[cloudId]
        : credentialsPayload;

    setFilterData(credentials);
    setDisplayFields(getDisplayFields(hostCloudOption));
  };

  useEffect(() => {
    if (selector) {
      setUser(selector.value);
    }
  }, [selector]);

  useEffect(() => {
    if (user) {
      getData();
      getVenderManagement();
    }

    if (selectedCloudOption) {
      setHostOptionsState(selectedCloudOption);
    }
  }, [user, selectedCloudOption]);

  const [isLoading, setIsLoading] = useState(false);

  // Host Configuration Values

  const [listOFVariables, setListOFVariables] = useState([
    {
      vairable: {
        key: '',
        value: '',
        key1: '',
        value1: '',
      },
    },
  ]);

  const addVariableRow = () => {
    setListOFVariables(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeVariableRow = index => {
    let newArray = listOFVariables.filter((val, ind) => ind !== index);
    setListOFVariables(newArray);
  };
  const [listOFConfig, setListOFConfig] = useState([
    {
      default: {
        name: '',
        content:
          'ewogICJ0eXBlIjogInNlcnZpY2VfYWNjb3VudCIsCiAgInByb2plY3RfaWQiOiAiZmlyc3QtcHJvdmlkZXItMzgxMDE1IiwKICAicHJpdmF0ZV9rZXlfaWQiOiAiNGYzYmZhMDNlNTVkYTA2ZGIzNTkzOTY5ZGE4NGFhZTYyMzM1MDQxO21ldGFkYXRhL3g1MDkvc2VydmljZS1wcmluY2lwYWwlNDBmaXJzdC1wcm92aWRlci0zODEwMTUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLAogICJ1bml2ZXJzZV9kb21haW4iOiAiZ29vZ2xlYXBpcy5jb20iCn0K',
      },
    },
  ]);
  const addConfigRow = () => {
    setListOFConfig(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeConfigRow = index => {
    let newArray = listOFConfig.filter((val, ind) => ind !== index);
    setListOFConfig(newArray);
  };
  const updateVariableValues = (index, indentifier, value) => {
    let newArr = listOFVariables.map((val, ind) => {
      if (ind === index) {
        return {
          vairable: {
            ...val?.vairable,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFVariables(newArr);
  };
  const updateConfigValues = (index, indentifier, value) => {
    let newArr = listOFConfig.map((val, ind) => {
      if (ind === index) {
        return {
          default: {
            ...val?.default,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFConfig(newArr);
  };

  // Peer1 Configuration Values

  const [listOFVariables2, setListOFVariables2] = useState([
    {
      vairable: {
        key: '',
        value: '',
        key1: '',
        value1: '',
      },
    },
  ]);
  const addVariableRow2 = () => {
    setListOFVariables2(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeVariableRow2 = index => {
    let newArray = listOFVariables2.filter((val, ind) => ind !== index);
    setListOFVariables2(newArray);
  };
  const [listOFConfig2, setListOFConfig2] = useState([
    {
      default: {
        name: '',
        content:
          'ewogICJ0eXBlIjogInNlcnZpY2VfYWNjb3VudCIsCiAgInByb2plY3RfaWQiOiAiZmlyc3QtcHJvdmlkZXItMzgxMDE1IiwKICAicHJpdmF0ZV9rZXlfaWQiOiAiNGYzYmZhMDNlNTVkYTA2ZGIzNTkzOTY5ZGE4NGFhZTYyMzM1MDQxO21ldGFkYXRhL3g1MDkvc2VydmljZS1wcmluY2lwYWwlNDBmaXJzdC1wcm92aWRlci0zODEwMTUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLAogICJ1bml2ZXJzZV9kb21haW4iOiAiZ29vZ2xlYXBpcy5jb20iCn0K',
      },
    },
  ]);
  const addConfigRow2 = () => {
    setListOFConfig2(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeConfigRow2 = index => {
    let newArray = listOFConfig2.filter((val, ind) => ind !== index);
    setListOFConfig2(newArray);
  };
  const updateVariableValues2 = (index, indentifier, value) => {
    let newArr = listOFVariables2.map((val, ind) => {
      if (ind === index) {
        return {
          vairable: {
            ...val?.vairable,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFVariables2(newArr);
  };
  const updateConfigValues2 = (index, indentifier, value) => {
    let newArr = listOFConfig2.map((val, ind) => {
      if (ind === index) {
        return {
          default: {
            ...val?.default,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFConfig2(newArr);
  };

  // Host Configuration Values

  const [listOFVariables3, setListOFVariables3] = useState([
    {
      vairable: {
        key: '',
        value: '',
        key1: '',
        value1: '',
      },
    },
  ]);
  const addVariableRow3 = () => {
    setListOFVariables3(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeVariableRow3 = index => {
    let newArray = listOFVariables3.filter((val, ind) => ind !== index);
    setListOFVariables3(newArray);
  };
  const [listOFConfig3, setListOFConfig3] = useState([
    {
      default: {
        name: '',
        content:
          'ewogICJ0eXBlIjogInNlcnZpY2VfYWNjb3VudCIsCiAgInByb2plY3RfaWQiOiAiZmlyc3QtcHJvdmlkZXItMzgxMDE1IiwKICAicHJpdmF0ZV9rZXlfaWQiOiAiNGYzYmZhMDNlNTVkYTA2ZGIzNTkzOTY5ZGE4NGFhZTYyMzM1MDQxO21ldGFkYXRhL3g1MDkvc2VydmljZS1wcmluY2lwYWwlNDBmaXJzdC1wcm92aWRlci0zODEwMTUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLAogICJ1bml2ZXJzZV9kb21haW4iOiAiZ29vZ2xlYXBpcy5jb20iCn0K',
      },
    },
  ]);
  const addConfigRow3 = () => {
    setListOFConfig3(prev => [
      ...prev,
      {
        vairable: {
          key: '',
          value: '',
          key2: '',
          value2: '',
        },
      },
    ]);
  };
  const removeConfigRow3 = index => {
    let newArray = listOFConfig3.filter((val, ind) => ind !== index);
    setListOFConfig3(newArray);
  };
  const updateVariableValues3 = (index, indentifier, value) => {
    let newArr = listOFVariables3.map((val, ind) => {
      if (ind === index) {
        return {
          vairable: {
            ...val?.vairable,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFVariables3(newArr);
  };
  const updateConfigValues3 = (index, indentifier, value) => {
    let newArr = listOFConfig3.map((val, ind) => {
      if (ind === index) {
        return {
          default: {
            ...val?.default,
            [indentifier]: value,
          },
        };
      } else {
        return val;
      }
    });
    setListOFConfig3(newArr);
  };

  const getDisplayFields = supportedVendorId => {
    console.log('supportedVendorId = ' + supportedVendorId);

    var vendor = hostData.find(item => {
      return item.id == supportedVendorId;
    });
    //console.log(vendor);
    return vendor.displayFields;
  };

  // Final Submit
  const [name, setName] = useState('');
  const submitUpdateConfig = async () => {
    setIsLoading(true);

    try {
      var cloudId = getCloudOptionId(selectedCloudOption);
      if (!cloudId) {
        toast({
          description: 'Please select cloud option..',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      }
      console.log(filterData);

      let response = await PUT(
        'api/v1/tenant/vendor/1/layer/' + cloudId,
        filterData
      );
      console.log(response);
      if (response?.id) {
        toast({
          description: 'Configurations Saved!',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
        getVenderManagement();
      } else {
        toast({
          description:
            response?.errors[0]?.message ?? 'All fields are required!',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        position: 'top-right',
        duration: 3000,
        isClosable: true,
        status: 'error',
      });
    }
    setIsLoading(false);
  };

  const setHostOptionsState = cloudOption => {
    var cloudId = getCloudOptionId(cloudOption);
    hostData.map(item => {
      if (
        data?.configurations[cloudId] == undefined ||
        data?.configurations[cloudId].id == undefined
      ) {
        item.disabled = false;
        setFilterData({});
        setDisplayFields({});
      } else {
        let cloud = data?.configurations[cloudId];
        let supportedVendorId = cloud.supportedVendorId;

        item.disabled = item.id != supportedVendorId;
        if (!item.disabled) {
          findData(supportedVendorId);
        }
      }
    });
  };

  const resetHostOptionsState = cloudOption => {
    console.log('working');
    hostData.map(item => {
      item.disabled = false;
    });
    const arr = [...hostData];
    setHostData(arr);
  };

  return (
    <SideWrapper>
      <Stack
        w={'full'}
        display={'flex'}
        flexDirection={'column'}
        margin={'40px 50px 30px 35px !important'}
      >
        {/* Current Location identifier */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <LocationHeader
            optionsList={optionsList}
            followingRoute={[
              selectedHostOption?.name,
              selectedPeer1Option?.name,
              selectedPeer2Option?.name,
            ]}
          />
          <Box mt="-30px !important">
            <Button
              ml="30px !important"
              role="group"
              bgColor={'primary.100'}
              color={'#fff'}
              padding={'12px 20px'}
              fontSize={'16px'}
              fontWeight={'500'}
              border={'2px solid #4A4A4A'}
              _hover={{
                bgColor: 'transparent',
                color: '#4A4A4A',
              }}
              onClick={() => {
                resetHostOptionsState();
              }}
            >
              Reset
              <Icon
                _groupHover={{ color: '#4A4A4A' }}
                color={'#fff'}
                ml="10px"
                as={BiReset}
                fontSize={22}
              />
            </Button>
            <NotificationIcon />
          </Box>
        </Stack>
        {/* Cloud options */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          // gap={'10px'}
          // padding={'35px 30px'}
          background={'#fff'}
          // borderRadius={'10px'}
          marginBottom={'20px !important'}
          flexWrap={'wrap'}
          borderRadius={'10px'}
          spacing={0}
          overflow={'hidden'}
        >
          {cloudOptions.map((v, i) => (
            <CloudOptionButton
              {...v}
              key={i}
              selectedCloudOption={selectedCloudOption}
              setSelectedCloudOption={setSelectedCloudOption}
              setHostOptionsState={setHostOptionsState}
              resetHostOptionsState={resetHostOptionsState}
            />
          ))}
        </Stack>
        {/* Selected cloud option extended*/}
        <SpacedStack>
          {selectedCloudOption === 'Host Cloud' ? (
            <>
              <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                {selectedCloudOption}
              </Heading>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                flexWrap={'wrap'}
                spacing={0}
              >
                {hostData?.length > 0 &&
                  hostData?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      index={i}
                      findData={findData}
                      selectedCloudOption={selectedHostOption}
                      setSelectedCloudOption={setSelectedHostOption}
                      isDisabled={v.disabled}
                    />
                  ))}
              </Stack>
            </>
          ) : selectedCloudOption === 'Peer 1' ? (
            <>
              <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                Peer 1
              </Heading>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                flexWrap={'wrap'}
                spacing={0}
              >
                {hostData?.length > 0 &&
                  hostData?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      index={i}
                      findData={findData}
                      selectedCloudOption={selectedPeer1Option}
                      setSelectedCloudOption={setSelectedPeer1Option}
                      isDisabled={v.disabled}
                    />
                  ))}
              </Stack>
            </>
          ) : selectedCloudOption === 'Peer 2' ? (
            <>
              <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                Peer 2
              </Heading>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                flexWrap={'wrap'}
                spacing={0}
              >
                {hostData?.length > 0 &&
                  hostData?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      index={i}
                      findData={findData}
                      selectedCloudOption={selectedPeer2Option}
                      setSelectedCloudOption={setSelectedPeer2Option}
                      isDisabled={v.disabled}
                    />
                  ))}
              </Stack>
            </>
          ) : selectedCloudOption === 'Additional Peers' ? (
            <>
              <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                Additional Peers
              </Heading>
              <AdditionalPeers />
            </>
          ) : (
            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                flexWrap={'wrap'}
                spacing={0}
              >
                {hostData?.length > 0 &&
                  hostData?.map((v, i) => (
                    <HostCloudButton
                      {...v}
                      key={i}
                      findData={findData}
                      selectedCloudOption={selectedHostOption}
                      setSelectedCloudOption={setSelectedHostOption}
                      isDisabled={v.disabled}
                    />
                  ))}
              </Stack>
            </>
          )}
          {selectedCloudOption !== 'Additional Peers' ? (
            <>
              <FormLabel>Name</FormLabel>
              <Input
                w="35%"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </>
          ) : null}
        </SpacedStack>
        {/* Extended selected host cloud */}
        {selectedHostOption && Object.keys(displayFields).length > 0 && (
          <StackWrapper>
            <CloudLogin
              getMainData={getMainData}
              sendData={sendData}
              mainVal={mainVal}
              filterData={filterData}
              setFilterData={setFilterData}
              listOFVariables={listOFVariables}
              setListOFVariables={setListOFVariables}
              addVariableRow={addVariableRow}
              removeVariableRow={removeVariableRow}
              updateVariableValues={updateVariableValues}
              listOFConfig={listOFConfig}
              setListOFConfig={setListOFConfig}
              addConfigRow={addConfigRow}
              removeConfigRow={removeConfigRow}
              updateConfigValues={updateConfigValues}
              submitUpdateConfig={submitUpdateConfig}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              displayFields={displayFields}
            />
          </StackWrapper>
        )}
      </Stack>
    </SideWrapper>
  );
}

const StackWrapper = ({ children }) => (
  <Stack
    direction={'row'}
    alignItems={'center'}
    gap={'10px'}
    padding={'35px 30px'}
    background={'#fff'}
    borderRadius={'10px'}
    marginBottom={'20px !important'}
    spacing={0}
  >
    {children}
  </Stack>
);
